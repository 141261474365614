import { gql } from 'graphql-tag';

export const listJobPositions = gql`
  query ListJobPositions($filter: ModelJobPositionFilterInput, $limit: Int, $nextToken: String) {
    listJobPositions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        hrrepresentativeID
        clientCompanyID
        taskdefinition
        positionUrl
        department
        desiredExperience
        educationLevel
        employmentType
        responsibilities
        qualifications
        location
        employmentLocationType
        salaryRange
        benefits
        applicationDeadline
        status
        skillRequirements {
          skill
          level
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getJobPosition = gql`
  query GetJobPosition($id: ID!) {
    getJobPosition(id: $id) {
      id
      name
      description
      hrrepresentativeID
      clientCompanyID
      taskdefinition
      positionUrl
      department
      desiredExperience
      educationLevel
      employmentType
      responsibilities
      qualifications
      location
      employmentLocationType
      salaryRange
      benefits
      applicationDeadline
      status
      skillRequirements {
        skill
        level
      }
      candidates {
        items {
          id
          status
        }
      }
      createdAt
      updatedAt
    }
  }
`;

export const getClientCompanyWithJobs = gql`
  query GetClientCompanyWithJobs($id: ID!) {
    getClientCompany(id: $id) {
      id
      name
      jobPositions {
        items {
          id
          name
          description
          status
          applicationDeadline
        }
      }
      createdAt
      updatedAt
    }
  }
`;

export const listCandidates = gql`
  query ListCandidates($filter: ModelCandidateFilterInput, $limit: Int, $nextToken: String) {
    listCandidates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        hrrepresentativeID
        overallScore
        feedback
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getCandidate = gql`
  query GetCandidate($id: ID!) {
    getCandidate(id: $id) {
      id
      name
      email
      hrrepresentativeID
      overallScore
      feedback
      jobPositions {
        items {
          id
          status
        }
      }
      exerciseInstances {
        items {
          id
          startTime
          endTime
          status
        }
      }
      createdAt
      updatedAt
    }
  }
`;

export const getHrrepresentative = gql`
  query GetHrrepresentative($id: ID!) {
    getHrrepresentative(id: $id) {
      id
      name
      email
      role
      companyID
      jobPositions {
        items {
          id
          name
        }
      }
      candidates {
        items {
          id
          name
        }
      }
      createdAt
      updatedAt
    }
  }
`;

export const listHrrepresentatives = gql`
  query ListHrrepresentatives($filter: ModelHrrepresentativeFilterInput, $limit: Int, $nextToken: String) {
    listHrrepresentatives(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        role
        companyID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getCompany = gql`
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      name
      type
      hrRepresentatives {
        items {
          id
          name
        }
      }
      jobPositions {
        items {
          id
          name
        }
      }
      candidates {
        items {
          id
          name
        }
      }
      clientCompanies {
        items {
          id
          name
        }
      }
      createdAt
      updatedAt
    }
  }
`;

export const getClientCompany = gql`
  query GetClientCompany($id: ID!) {
    getClientCompany(id: $id) {
      id
      name
      jobPositions {
        items {
          id
          name
        }
      }
      createdAt
      updatedAt
    }
  }
`;

export const listClientCompanies = gql`
  query ListClientCompanies($filter: ModelClientCompanyFilterInput, $limit: Int, $nextToken: String) {
    listClientCompanies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;


export const listInterviews = gql`
  query ListInterviews($filter: ModelInterviewFilterInput, $limit: Int, $nextToken: String) {
    listInterviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        jobPositionID
        candidateID
        hrRepresentativeID
        scheduledTime
        duration
        type
        status
        notes
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getInterview = gql`
  query GetInterview($id: ID!) {
    getInterview(id: $id) {
      id
      jobPositionID
      candidateID
      hrRepresentativeID
      scheduledTime
      duration
      type
      status
      notes
      createdAt
      updatedAt
    }
  }
`;