import React, { useState } from 'react';
import { resetPassword, confirmResetPassword } from 'aws-amplify/auth';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

const EmailSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
});

const CodeSchema = Yup.object().shape({
  code: Yup.string().required('Required'),
});

const PasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .matches(/[a-z]/, 'Password must contain at least one lowercase char')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase char')
    .matches(/[0-9]/, 'Password must contain at least one number')
    .matches(/[^a-zA-Z0-9]/, 'Password must contain at least one special char')
    .required('Required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    .required('Required'),
});

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    backgroundColor: 'white',
    color: '#333333',
    fontFamily: 'Arial, sans-serif',
  },
  form: {
    width: '100%',
    maxWidth: '400px',
    padding: '30px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    backgroundColor: 'white',
  },
  title: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    color: '#4adada',
    marginBottom: '20px',
    textAlign: 'center',
  },
  input: {
    width: '100%',
    padding: '10px 15px',
    marginBottom: '15px',
    border: '1px solid #ddd',
    borderRadius: '4px',
    fontSize: '1rem',
  },
  button: {
    width: '100%',
    padding: '12px 20px',
    backgroundColor: '#4adada',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    fontSize: '1rem',
    fontWeight: 'bold',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  },
  errorMessage: {
    color: 'red',
    marginBottom: '15px',
    fontSize: '0.9rem',
  },
  successMessage: {
    color: 'green',
    marginBottom: '15px',
    fontSize: '0.9rem',
  },
  link: {
    color: '#4adada',
    textDecoration: 'none',
    cursor: 'pointer',
    marginTop: '15px',
    display: 'inline-block',
  },
};

const ForgotPassword = ({ onBackToSignIn }) => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [email, setEmail] = useState('');
  const [step, setStep] = useState(1);
  const [code, setCode] = useState('');

  const handleSendCode = async (values, { setSubmitting }) => {
    try {
      await resetPassword({ username: values.email });
      setEmail(values.email);
      setError(null);
      setSuccess('Code sent successfully. Please check your email.');
      setStep(2);
    } catch (err) {
      setError(err.message);
      setSuccess(null);
    }
    setSubmitting(false);
  };

  const handleVerifyCode = async (values, { setSubmitting }) => {
    setCode(values.code);
    setError(null);
    setSuccess('Code verified. Please enter your new password.');
    setStep(3);
    setSubmitting(false);
  };

  const handleResetPassword = async (values, { setSubmitting }) => {
    try {
      await confirmResetPassword({
        username: email,
        confirmationCode: code,
        newPassword: values.newPassword,
      });
      setError(null);
      setSuccess('Password reset successfully. Redirecting to sign in...');
      setTimeout(() => {
        onBackToSignIn();
      }, 3000);
    } catch (err) {
      setError(err.message);
      setSuccess(null);
    }
    setSubmitting(false);
  };

  return (
    <div style={styles.container}>
      <div style={styles.form}>
        <h2 style={styles.title}>Forgot Password</h2>
        {step === 1 && (
          <Formik
            initialValues={{ email: '' }}
            validationSchema={EmailSchema}
            onSubmit={handleSendCode}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form>
                <Field name="email" type="email" placeholder="Email" style={styles.input} />
                {errors.email && touched.email && <div style={styles.errorMessage}>{errors.email}</div>}
                <button type="submit" disabled={isSubmitting} style={styles.button}>
                  Send Code
                </button>
              </Form>
            )}
          </Formik>
        )}
        {step === 2 && (
          <Formik
            initialValues={{ code: '' }}
            validationSchema={CodeSchema}
            onSubmit={handleVerifyCode}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form>
                <Field name="code" type="text" placeholder="Verification Code" style={styles.input} />
                {errors.code && touched.code && <div style={styles.errorMessage}>{errors.code}</div>}
                <button type="submit" disabled={isSubmitting} style={styles.button}>
                  Verify Code
                </button>
              </Form>
            )}
          </Formik>
        )}
        {step === 3 && (
          <Formik
            initialValues={{ newPassword: '', confirmPassword: '' }}
            validationSchema={PasswordSchema}
            onSubmit={handleResetPassword}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form>
                <Field name="newPassword" type="password" placeholder="New Password" style={styles.input} />
                {errors.newPassword && touched.newPassword && (
                  <div style={styles.errorMessage}>{errors.newPassword}</div>
                )}
                <Field name="confirmPassword" type="password" placeholder="Confirm New Password" style={styles.input} />
                {errors.confirmPassword && touched.confirmPassword && (
                  <div style={styles.errorMessage}>{errors.confirmPassword}</div>
                )}
                <button type="submit" disabled={isSubmitting} style={styles.button}>
                  Change Password
                </button>
              </Form>
            )}
          </Formik>
        )}
        {error && <div style={styles.errorMessage}>{error}</div>}
        {success && <div style={styles.successMessage}>{success}</div>}
        <div style={styles.toggle}>
          <span style={styles.link} onClick={onBackToSignIn}>Back to Sign In</span>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;