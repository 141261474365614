import React from 'react';

const styles = {
  container: {
    padding: '20px',
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '20px',
    color: '#333',
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '20px',
  },
  card: {
    flex: 1,
    padding: '20px',
    borderRadius: '8px',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  cardTitle: {
    fontSize: '18px',
    marginBottom: '10px',
  },
  cardValue: {
    fontSize: '28px',
    fontWeight: 'bold',
  },
};

const DashboardOverview = ({ positions, candidates }) => (
  <div style={styles.container}>
    <h2 style={styles.title}>Dashboard Overview</h2>
    <div style={styles.cardContainer}>
      <div style={{...styles.card, backgroundColor: '#4adada'}}>
        <h3 style={styles.cardTitle}>Open Positions</h3>
        <p style={styles.cardValue}>{positions.filter(p => p.status === 'Open').length}</p>
      </div>
      <div style={{...styles.card, backgroundColor: '#10B981'}}>
        <h3 style={styles.cardTitle}>Total Positions</h3>
        <p style={styles.cardValue}>{positions.length}</p>
      </div>
      <div style={{...styles.card, backgroundColor: '#F59E0B'}}>
        <h3 style={styles.cardTitle}>Total Candidates</h3>
        <p style={styles.cardValue}>{candidates.length}</p>
      </div>
    </div>
  </div>
);

export default DashboardOverview;