import { useState, useEffect, useCallback } from 'react';
import { generateClient } from 'aws-amplify/api';
import { fetchAuthSession, getCurrentUser } from '@aws-amplify/auth';
import { listJobPositions, listCandidates, listHrrepresentatives, listClientCompanies, listInterviews } from '../graphql/queries';

const client = generateClient();

export const useDataFetching = (user) => {
  const [positions, setPositions] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [interviews, setInterviews] = useState([]);
  const [hrRepresentatives, setHrRepresentatives] = useState([]);
  const [clientCompanies, setClientCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const refreshToken = async () => {
    try {
      await fetchAuthSession();
      await getCurrentUser();
    } catch (error) {
      console.error('Error refreshing token:', error);
      throw new Error('Authentication failed. Please log in again.');
    }
  };

  const handleFetchError = useCallback(async (operation, err) => {
    console.error(`Error ${operation}:`, err);
    const errorMessage = err.message || err.toString() || 'An unknown error occurred';
    if (errorMessage.includes('Not Authorized')) {
      try {
        await refreshToken();
        setError(`Please try fetching ${operation} again.`);
      } catch (refreshError) {
        setError('Authentication failed. Please log in again.');
      }
    } else {
      setError(`Failed to fetch ${operation}. ${errorMessage}`);
    }
  }, []);

  const fetchPositions = useCallback(async () => {
    try {
      const positionData = await client.graphql({ 
        query: listJobPositions,
        variables: user.role === 'HR' ? { filter: { hrrepresentativeID: { eq: user.id } } } : {}
      });
      console.log('Fetched positions:', positionData.data.listJobPositions.items);
      const cleanedPositions = positionData.data.listJobPositions.items.map(position => ({
        ...position,
        name: position.name || 'Untitled Position',
        hrrepresentativeID: position.hrrepresentativeID || 'Unassigned',
        companyID: position.companyID || 'Unassigned',
        updatedAt: position.updatedAt || position.createdAt || new Date().toISOString()
      }));
      setPositions(cleanedPositions);
    } catch (err) {
      handleFetchError('positions', err);
    }
  }, [user, handleFetchError]);

  const fetchCandidates = useCallback(async () => {
    try {
      const candidateData = await client.graphql({ 
        query: listCandidates,
        variables: user.role === 'HR' ? { filter: { hrrepresentativeID: { eq: user.id } } } : {}
      });
      console.log('Fetched candidates:', candidateData.data.listCandidates.items);
      const cleanedCandidates = candidateData.data.listCandidates.items.map(candidate => ({
        ...candidate,
        name: candidate.name || 'Unnamed Candidate',
        hrrepresentativeID: candidate.hrrepresentativeID || 'Unassigned',
        companyID: candidate.companyID || 'Unassigned',
        updatedAt: candidate.updatedAt || candidate.createdAt || new Date().toISOString()
      }));
      setCandidates(cleanedCandidates);
    } catch (err) {
      console.error('Error fetching candidates:', err);
      if (err.errors) {
        console.error('GraphQL errors:', JSON.stringify(err.errors, null, 2));
      }
      const errorMessage = err.errors ? err.errors.map(e => e.message).join(', ') : err.message || 'An unknown error occurred';
      handleFetchError('candidates', new Error(errorMessage));
    }
  }, [user, handleFetchError]);
  
  const fetchHrRepresentatives = useCallback(async () => {
    try {
      const hrData = await client.graphql({ query: listHrrepresentatives });
      console.log('Fetched HR representatives:', hrData.data.listHrrepresentatives.items);
      const cleanedHrReps = hrData.data.listHrrepresentatives.items.map(hrRep => ({
        ...hrRep,
        name: hrRep.name || 'Unnamed Representative',
        role: hrRep.role || 'Unspecified Role',
        companyID: hrRep.companyID || 'Unassigned',
        updatedAt: hrRep.updatedAt || hrRep.createdAt || new Date().toISOString()
      }));
      setHrRepresentatives(cleanedHrReps);
    } catch (err) {
      handleFetchError('HR representatives', err);
    }
  }, [handleFetchError]);

  const fetchClientCompanies = useCallback(async () => {
    try {
      const companyData = await client.graphql({ query: listClientCompanies });
      console.log('Fetched client companies:', companyData.data.listClientCompanies.items);
      const cleanedCompanies = companyData.data.listClientCompanies.items.map(company => ({
        ...company,
        name: company.name || 'Unnamed Company',
        updatedAt: company.updatedAt || company.createdAt || new Date().toISOString()
      }));
      setClientCompanies(cleanedCompanies);
    } catch (err) {
      handleFetchError('client companies', err);
    }
  }, [handleFetchError]);

  const fetchInterviews = useCallback(async () => {
    try {
      const interviewData = await client.graphql({ 
        query: listInterviews,
        variables: user.role === 'HR' ? { filter: { hrRepresentativeID: { eq: user.id } } } : {}
      });
      console.log('Fetched interviews:', interviewData.data.listInterviews.items);
      const cleanedInterviews = interviewData.data.listInterviews.items.map(interview => ({
        ...interview,
        jobPositionID: interview.jobPositionID || 'Unassigned',
        candidateID: interview.candidateID || 'Unassigned',
        hrRepresentativeID: interview.hrRepresentativeID || 'Unassigned',
        scheduledTime: interview.scheduledTime || new Date().toISOString(),
        updatedAt: interview.updatedAt || interview.createdAt || new Date().toISOString()
      }));
      setInterviews(cleanedInterviews);
    } catch (err) {
      handleFetchError('interviews', err);
    }
  }, [user, handleFetchError]);

  useEffect(() => {
    const fetchAllData = async () => {
      setLoading(true);
      setError(null);
      try {
        await getCurrentUser();
        await Promise.all([
          fetchPositions(),
          fetchCandidates(),
          fetchHrRepresentatives(),
          fetchClientCompanies(),
          fetchInterviews()
        ]);
      } catch (err) {
        console.error('Error fetching data:', err);
        const errorMessage = err.message || err.toString() || 'An unknown error occurred';
        if (errorMessage.includes('The user is not authenticated')) {
          setError('Please log in to access the data.');
        } else {
          setError(`Failed to fetch data. ${errorMessage}`);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();
  }, [fetchPositions, fetchCandidates, fetchHrRepresentatives, fetchClientCompanies, fetchInterviews]);

  return {
    positions,
    candidates,
    interviews,
    hrRepresentatives,
    clientCompanies,
    loading,
    error,
    setPositions,
    setCandidates,
    setInterviews,
    setError,
    refreshData: useCallback(() => {
      fetchPositions();
      fetchCandidates();
      fetchHrRepresentatives();
      fetchClientCompanies();
      fetchInterviews();
    }, [fetchPositions, fetchCandidates, fetchHrRepresentatives, fetchClientCompanies, fetchInterviews])
  };
};

export default useDataFetching;