import React, { useState, useEffect } from 'react';

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflowY: 'auto',
  },
  modal: {
    backgroundColor: 'white',
    padding: '30px',
    borderRadius: '8px',
    width: '80%',
    maxWidth: '600px',
    maxHeight: '80vh',
    overflowY: 'auto',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },
  title: {
    marginBottom: '20px',
    color: '#333',
    fontSize: '24px',
  },
  input: {
    display: 'block',
    width: '100%',
    marginBottom: '15px',
    padding: '10px',
    borderRadius: '4px',
    border: '1px solid #e2e8f0',
    fontSize: '16px',
  },
  select: {
    display: 'block',
    width: '100%',
    marginBottom: '15px',
    padding: '10px',
    borderRadius: '4px',
    border: '1px solid #e2e8f0',
    fontSize: '16px',
    backgroundColor: 'white',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '25px',
  },
  button: {
    padding: '10px 20px',
    borderRadius: '4px',
    border: 'none',
    cursor: 'pointer',
    fontSize: '16px',
    transition: 'background-color 0.3s ease',
  },
  cancelButton: {
    backgroundColor: '#EF4444',
    color: 'white',
  },
  submitButton: {
    backgroundColor: '#4adada',
    color: 'white',
  },
  addSkillButton: {
    backgroundColor: '#3B82F6',
    color: 'white',
    marginBottom: '15px',
  },
  removeSkillButton: {
    backgroundColor: '#EF4444',
    color: 'white',
    padding: '5px 10px',
    marginTop: '5px',
    fontSize: '14px',
  },
  skillContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  skillInput: {
    width: '45%',
    marginRight: '5%',
  },
};

const initialFormState = {
  name: '',
  description: '',
  department: '',
  desiredExperience: '',
  educationLevel: '',
  employmentType: '',
  responsibilities: [],
  qualifications: [],
  location: '',
  employmentLocationType: '',
  salaryRange: '',
  benefits: [],
  applicationDeadline: '',
  status: '',
  skillRequirements: [],
  clientCompanyID: '',
};

const AddEditPositionModal = ({ mode, item, onClose, onSubmit, currentUser, clientCompanies }) => {
  const [formData, setFormData] = useState(initialFormState);

  useEffect(() => {
    if (mode === 'edit' && item) {
      const localDate = item.applicationDeadline ? new Date(item.applicationDeadline).toISOString().split('T')[0] : '';
      setFormData({
        ...initialFormState,
        ...item,
        applicationDeadline: localDate,
        responsibilities: item.responsibilities || [],
        qualifications: item.qualifications || [],
        benefits: item.benefits || [],
        skillRequirements: item.skillRequirements || [],
        clientCompanyID: item.clientCompanyID || '',
      });
    } else {
      setFormData(initialFormState);
    }
  }, [mode, item]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleArrayChange = (e, field) => {
    const { value } = e.target;
    setFormData(prev => ({ ...prev, [field]: value ? value.split(',').map(item => item.trim()) : [] }));
  };

  const handleSkillChange = (index, field, value) => {
    const updatedSkills = [...formData.skillRequirements];
    updatedSkills[index] = { ...updatedSkills[index], [field]: value };
    setFormData(prev => ({
      ...prev,
      skillRequirements: updatedSkills
    }));
  };

  const addSkillRequirement = () => {
    setFormData(prev => ({
      ...prev,
      skillRequirements: [...prev.skillRequirements, { skill: '', level: '' }]
    }));
  };

  const removeSkillRequirement = (index) => {
    setFormData(prev => ({
      ...prev,
      skillRequirements: prev.skillRequirements.filter((_, i) => i !== index)
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const requiredFields = ['name', 'clientCompanyID', 'status']; // Add any other required fields
    const missingFields = requiredFields.filter(field => !formData[field]);
    
    if (missingFields.length > 0) {
      alert(`Please fill in the following required fields: ${missingFields.join(', ')}`);
      return;
    }
  
    const submissionData = {
      ...formData,
      hrrepresentativeID: currentUser.id,
      applicationDeadline: formData.applicationDeadline ? new Date(formData.applicationDeadline).toISOString() : null,
    };
    
    if (mode === 'add') {
      submissionData.companyID = currentUser.companyID;
    } else if (mode === 'edit') {
      submissionData.id = item.id;
    }
    
    // Remove any fields that are empty strings, null, or undefined
    Object.keys(submissionData).forEach(key => {
      if (submissionData[key] === '' || submissionData[key] === null || submissionData[key] === undefined) {
        delete submissionData[key];
      }
    });
  
    onSubmit(submissionData);
    if (mode === 'add') {
      setFormData(initialFormState);
    }
  };

  return (
    <div style={styles.overlay}>
      <div style={styles.modal}>
        <h2 style={styles.title}>{mode === 'add' ? 'Add' : 'Edit'} Position</h2>
        <form onSubmit={handleSubmit}>
          <input type="text" name="name" placeholder="Position Name" value={formData.name || ''} onChange={handleChange} style={styles.input} required />
          <textarea name="description" placeholder="Description" value={formData.description || ''} onChange={handleChange} style={{...styles.input, height: '100px'}} />
          <input type="text" name="department" placeholder="Department" value={formData.department || ''} onChange={handleChange} style={styles.input} />
          <input type="text" name="desiredExperience" placeholder="Desired Experience" value={formData.desiredExperience || ''} onChange={handleChange} style={styles.input} />
          
          <select name="clientCompanyID" value={formData.clientCompanyID || ''} onChange={handleChange} style={styles.select} required>
            <option value="">Select Client Company</option>
            {clientCompanies.map(company => (
              <option key={company.id} value={company.id}>{company.name}</option>
            ))}
          </select>
          
          <select name="educationLevel" value={formData.educationLevel || ''} onChange={handleChange} style={styles.select}>
            <option value="">Select Education Level</option>
            <option value="HighSchool">High School</option>
            <option value="Bachelors">Bachelors</option>
            <option value="Masters">Masters</option>
            <option value="PhD">PhD</option>
            <option value="Other">Other</option>
          </select>
          
          <select name="employmentType" value={formData.employmentType || ''} onChange={handleChange} style={styles.select}>
            <option value="">Select Employment Type</option>
            <option value="FullTime">Full Time</option>
            <option value="PartTime">Part Time</option>
            <option value="Contract">Contract</option>
            <option value="Temporary">Temporary</option>
            <option value="Internship">Internship</option>
          </select>
          
          <textarea name="responsibilities" placeholder="Responsibilities (comma-separated)" value={formData.responsibilities.join(', ')} onChange={(e) => handleArrayChange(e, 'responsibilities')} style={{...styles.input, height: '100px'}} />
          <textarea name="qualifications" placeholder="Qualifications (comma-separated)" value={formData.qualifications.join(', ')} onChange={(e) => handleArrayChange(e, 'qualifications')} style={{...styles.input, height: '100px'}} />
          <input type="text" name="location" placeholder="Location" value={formData.location || ''} onChange={handleChange} style={styles.input} />
          
          <select name="employmentLocationType" value={formData.employmentLocationType || ''} onChange={handleChange} style={styles.select}>
            <option value="">Select Employment Location Type</option>
            <option value="OnSite">On Site</option>
            <option value="Remote">Remote</option>
            <option value="Hybrid">Hybrid</option>
          </select>
          
          <input type="text" name="salaryRange" placeholder="Salary Range" value={formData.salaryRange || ''} onChange={handleChange} style={styles.input} />
          <textarea name="benefits" placeholder="Benefits (comma-separated)" value={formData.benefits.join(', ')} onChange={(e) => handleArrayChange(e, 'benefits')} style={{...styles.input, height: '100px'}} />
          <input type="date" name="applicationDeadline" value={formData.applicationDeadline || ''} onChange={handleChange} style={styles.input} />
          
          <select name="status" value={formData.status || ''} onChange={handleChange} style={styles.select} required>
            <option value="">Select Status</option>
            <option value="Open">Open</option>
            <option value="Closed">Closed</option>
            <option value="OnHold">On Hold</option>
          </select>
          
          <h3 style={{marginBottom: '10px', marginTop: '20px'}}>Skill Requirements</h3>
          {formData.skillRequirements.map((skill, index) => (
            <div key={index} style={styles.skillContainer}>
              <select
                value={skill.skill || ''}
                onChange={(e) => handleSkillChange(index, 'skill', e.target.value)}
                style={{...styles.select, ...styles.skillInput}}
              >
                <option value="">Select Skill</option>
                <option value="LINUX">Linux</option>
                <option value="ANSIBLE">Ansible</option>
                <option value="KUBERNETES">Kubernetes</option>
                <option value="JAVASCRIPT">JavaScript</option>
                <option value="PYTHON">Python</option>
                <option value="DOCKER">Docker</option>
              </select>
              <select
                value={skill.level || ''}
                onChange={(e) => handleSkillChange(index, 'level', e.target.value)}
                style={{...styles.select, ...styles.skillInput}}
              >
                <option value="">Select Level</option>
                <option value="JUNIOR">Junior</option>
                <option value="BEGINNER">Beginner</option>
                <option value="INTERMEDIATE">Intermediate</option>
                <option value="ADVANCED">Advanced</option>
              </select>
              <button type="button" onClick={() => removeSkillRequirement(index)} style={{...styles.button, ...styles.removeSkillButton}}>Remove</button>
            </div>
          ))}
          <button type="button" onClick={addSkillRequirement} style={{...styles.button, ...styles.addSkillButton}}>Add Skill Requirement</button>
          
          <div style={styles.buttonContainer}>
            <button type="button" onClick={onClose} style={{...styles.button, ...styles.cancelButton}}>Cancel</button>
            <button type="submit" style={{...styles.button, ...styles.submitButton}}>{mode === 'add' ? 'Add' : 'Update'}</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddEditPositionModal;