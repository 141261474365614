import { gql } from 'graphql-tag';

export const createJobPosition = gql`
  mutation CreateJobPosition($input: CreateJobPositionInput!) {
    createJobPosition(input: $input) {
      id
      name
      description
      hrrepresentativeID
      clientCompanyID
      taskdefinition
      positionUrl
      department
      desiredExperience
      educationLevel
      employmentType
      responsibilities
      qualifications
      location
      employmentLocationType
      salaryRange
      benefits
      applicationDeadline
      status
      skillRequirements {
        skill
        level
      }
      createdAt
      updatedAt
    }
  }
`;

export const updateJobPosition = gql`
  mutation UpdateJobPosition($input: UpdateJobPositionInput!) {
    updateJobPosition(input: $input) {
      id
      name
      description
      hrrepresentativeID
      clientCompanyID
      taskdefinition
      positionUrl
      department
      desiredExperience
      educationLevel
      employmentType
      responsibilities
      qualifications
      location
      employmentLocationType
      salaryRange
      benefits
      applicationDeadline
      status
      skillRequirements {
        skill
        level
      }
      createdAt
      updatedAt
    }
  }
`;

export const deleteJobPosition = gql`
  mutation DeleteJobPosition($input: DeleteJobPositionInput!) {
    deleteJobPosition(input: $input) {
      id
    }
  }
`;

export const createCandidate = gql`
  mutation CreateCandidate($input: CreateCandidateInput!) {
    createCandidate(input: $input) {
      id
      name
      email
      companyID
      hrrepresentativeID
      overallScore
      feedback
      phoneNumber
      resume
      availabilityDate
      desiredSalary
      profileCompleteness
      lastActive
    }
  }
`;

export const updateCandidate = gql`
  mutation UpdateCandidate($input: UpdateCandidateInput!) {
    updateCandidate(input: $input) {
      id
      name
      email
      overallScore
      feedback
      hrrepresentativeID
      createdAt
      updatedAt
    }
  }
`;

export const deleteCandidate = gql`
  mutation DeleteCandidate($input: DeleteCandidateInput!) {
    deleteCandidate(input: $input) {
      id
    }
  }
`;

export const createJobPositionCandidate = gql`
  mutation CreateJobPositionCandidate($input: CreateJobPositionCandidateInput!) {
    createJobPositionCandidate(input: $input) {
      id
      jobPositionID
      candidateID
      status
      clientCompanyID
      createdAt
      updatedAt
    }
  }
`;

export const createHrrepresentative = gql`
  mutation CreateHrrepresentative($input: CreateHrrepresentativeInput!) {
    createHrrepresentative(input: $input) {
      id
      name
      email
      role
      companyID
      createdAt
      updatedAt
    }
  }
`;

export const createCompany = gql`
  mutation CreateCompany($input: CreateCompanyInput!) {
    createCompany(input: $input) {
      id
      name
      type
      createdAt
      updatedAt
    }
  }
`;

export const createClientCompany = gql`
  mutation CreateClientCompany($input: CreateClientCompanyInput!) {
    createClientCompany(input: $input) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

export const createInterview = gql`
  mutation CreateInterview($input: CreateInterviewInput!) {
    createInterview(input: $input) {
      id
      jobPositionID
      candidateID
      hrRepresentativeID
      scheduledTime
      duration
      type
      status
      notes
      createdAt
      updatedAt
    }
  }
`;

export const updateInterview = gql`
  mutation UpdateInterview($input: UpdateInterviewInput!) {
    updateInterview(input: $input) {
      id
      jobPositionID
      candidateID
      hrRepresentativeID
      scheduledTime
      duration
      type
      status
      notes
      createdAt
      updatedAt
    }
  }
`;

export const deleteInterview = gql`
  mutation DeleteInterview($input: DeleteInterviewInput!) {
    deleteInterview(input: $input) {
      id
    }
  }
`;