import React from 'react';

const styles = {
  table: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: '0 8px',
    marginTop: '20px',
  },
  th: {
    padding: '16px',
    backgroundColor: '#4adada',
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'left',
  },
  td: {
    padding: '16px',
    borderBottom: '1px solid #e2e8f0',
    color: '#333333',
  },
  tr: {
    transition: 'all 0.3s ease',
  },
  button: {
    padding: '8px 16px',
    marginRight: '8px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  },
  editButton: {
    backgroundColor: '#4adada',
    color: 'white',
  },
  deleteButton: {
    backgroundColor: '#EF4444',
    color: 'white',
  },
};

const InterviewsTable = ({ interviews, candidates, positions, onEdit, onDelete, newItemId }) => {
  const getCandidateName = (candidateId) => {
    const candidate = candidates.find(c => c.id === candidateId);
    return candidate ? candidate.name : 'N/A';
  };

  const getPositionName = (positionId) => {
    const position = positions.find(p => p.id === positionId);
    return position ? position.name : 'N/A';
  };

  return (
    <table style={styles.table}>
      <thead>
        <tr>
          <th style={styles.th}>Candidate</th>
          <th style={styles.th}>Position</th>
          <th style={styles.th}>Scheduled Time</th>
          <th style={styles.th}>Duration</th>
          <th style={styles.th}>Type</th>
          <th style={styles.th}>Status</th>
          <th style={styles.th}>Actions</th>
        </tr>
      </thead>
      <tbody>
        {interviews.map((interview) => (
          <tr 
            key={interview.id} 
            style={{
              ...styles.tr,
              backgroundColor: interview.id === newItemId ? '#FECACA' : 'white',
            }}
          >
            <td style={styles.td}>{getCandidateName(interview.candidateID)}</td>
            <td style={styles.td}>{getPositionName(interview.jobPositionID)}</td>
            <td style={styles.td}>{new Date(interview.scheduledTime).toLocaleString()}</td>
            <td style={styles.td}>{interview.duration} minutes</td>
            <td style={styles.td}>{interview.type}</td>
            <td style={styles.td}>{interview.status}</td>
            <td style={styles.td}>
              <button 
                onClick={() => onEdit(interview)} 
                style={{...styles.button, ...styles.editButton}}
              >
                Edit
              </button>
              <button 
                onClick={() => onDelete(interview.id)} 
                style={{...styles.button, ...styles.deleteButton}}
              >
                Delete
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default InterviewsTable;