import React, { useState } from 'react';
import { confirmSignUp } from 'aws-amplify/auth';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

const ConfirmSignUpSchema = Yup.object().shape({
  code: Yup.string().required('Required'),
});

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    backgroundColor: 'white',
    color: '#333333',
    fontFamily: 'Arial, sans-serif',
  },
  form: {
    width: '100%',
    maxWidth: '400px',
    padding: '30px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    backgroundColor: 'white',
  },
  title: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    color: '#4adada',
    marginBottom: '20px',
    textAlign: 'center',
  },
  input: {
    width: '100%',
    padding: '10px 15px',
    marginBottom: '15px',
    border: '1px solid #ddd',
    borderRadius: '4px',
    fontSize: '1rem',
  },
  button: {
    width: '100%',
    padding: '12px 20px',
    backgroundColor: '#4adada',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    fontSize: '1rem',
    fontWeight: 'bold',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  },
  errorMessage: {
    color: 'red',
    marginBottom: '15px',
    fontSize: '0.9rem',
  },
};

const ConfirmSignUp = ({ email, onConfirm }) => {
  const [error, setError] = useState(null);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      await confirmSignUp({ username: email, confirmationCode: values.code });
      onConfirm();
    } catch (err) {
      setError(err.message);
    }
    setSubmitting(false);
  };

  return (
    <div style={styles.container}>
      <div style={styles.form}>
        <h2 style={styles.title}>Confirm Sign Up</h2>
        <Formik
          initialValues={{ code: '' }}
          validationSchema={ConfirmSignUpSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form>
              <Field name="code" type="text" placeholder="Confirmation Code" style={styles.input} />
              {errors.code && touched.code && <div style={styles.errorMessage}>{errors.code}</div>}

              <button
                type="submit"
                disabled={isSubmitting}
                style={styles.button}
              >
                Confirm
              </button>
            </Form>
          )}
        </Formik>
        {error && <div style={styles.errorMessage}>{error}</div>}
      </div>
    </div>
  );
};

export default ConfirmSignUp;