import { useEffect, useCallback } from 'react';
import { generateClient } from 'aws-amplify/api';
import { getCurrentUser } from '@aws-amplify/auth';
import { onCreateJobPosition, onCreateCandidate, onUpdateCandidate, onCreateInterview } from '../graphql/subscriptions';

const client = generateClient();

export const useSubscriptions = (user, setPositions, setCandidates, setInterviews, setNewItemId, setError) => {
  const handleSubscriptionError = useCallback((subscriptionType, error) => {
    console.error(`${subscriptionType} subscription error:`, error);
    if (error.message.includes('Not Authorized')) {
      setError(`Not authorized for ${subscriptionType} subscription. Please check your permissions.`);
    } else {
      setError(`Error in ${subscriptionType} subscription. Please try reconnecting.`);
    }
  }, [setError]);

  const subscribeToNewPositions = useCallback(() => {
    return client.graphql({ query: onCreateJobPosition }).subscribe({
      next: ({ data }) => {
        console.log('Received new position data:', data);
        if (data && data.onCreateJobPosition) {
          const newPosition = data.onCreateJobPosition;
          setPositions((prevPositions) => {
            const exists = prevPositions.some(pos => pos.id === newPosition.id);
            if (!exists) {
              return [...prevPositions, newPosition];
            }
            return prevPositions;
          });
          setNewItemId(newPosition.id);
          setTimeout(() => setNewItemId(null), 3000);
        }
      },
      error: (error) => handleSubscriptionError('Position', error),
    });
  }, [setPositions, setNewItemId, handleSubscriptionError]);

  const subscribeToNewCandidates = useCallback(() => {
    return client.graphql({ query: onCreateCandidate }).subscribe({
      next: ({ data }) => {
        console.log('Received new candidate data:', data);
        if (data && data.onCreateCandidate) {
          const newCandidate = data.onCreateCandidate;
          setCandidates((prevCandidates) => {
            const exists = prevCandidates.some(cand => cand.id === newCandidate.id);
            if (!exists) {
              return [...prevCandidates, newCandidate];
            }
            return prevCandidates;
          });
          setNewItemId(newCandidate.id);
          setTimeout(() => setNewItemId(null), 3000);
        }
      },
      error: (error) => handleSubscriptionError('Candidate', error),
    });
  }, [setCandidates, setNewItemId, handleSubscriptionError]);

  const subscribeToHRUpdates = useCallback(() => {
    return client.graphql({ query: onUpdateCandidate }).subscribe({
      next: ({ data }) => {
        if (data.onUpdateCandidate.hrrepresentativeID === user.id) {
          setCandidates(prevCandidates => 
            prevCandidates.map(c => 
              c.id === data.onUpdateCandidate.id ? data.onUpdateCandidate : c
            )
          );
        }
      },
      error: (error) => handleSubscriptionError('HR update', error),
    });
  }, [user.id, setCandidates, handleSubscriptionError]);

  const subscribeToInterviews = useCallback(() => {
    return client.graphql({ query: onCreateInterview }).subscribe({
      next: ({ data }) => {
        if (data && data.onCreateInterview) {
          const newInterview = data.onCreateInterview;
          setInterviews((prevInterviews) => [...prevInterviews, newInterview]);
          setNewItemId(newInterview.id);
          setTimeout(() => setNewItemId(null), 3000);
        }
      },
      error: (error) => handleSubscriptionError('Interview', error),
    });
  }, [setInterviews, setNewItemId, handleSubscriptionError]);

  useEffect(() => {
    let positionSubscription;
    let candidateSubscription;
    let hrUpdateSubscription;
    let interviewSubscription;

    const setupSubscriptions = async () => {
      try {
        await getCurrentUser();
        positionSubscription = subscribeToNewPositions();
        candidateSubscription = subscribeToNewCandidates();
        if (user.role === 'HR') {
          hrUpdateSubscription = subscribeToHRUpdates();
        }
        interviewSubscription = subscribeToInterviews();
      } catch (error) {
        console.error('Error setting up subscriptions:', error);
        setError('Failed to set up real-time updates. Please check your connection and try again.');
      }
    };

    setupSubscriptions();

    return () => {
      if (positionSubscription) positionSubscription.unsubscribe();
      if (candidateSubscription) candidateSubscription.unsubscribe();
      if (hrUpdateSubscription) hrUpdateSubscription.unsubscribe();
      if (interviewSubscription) interviewSubscription.unsubscribe();
    };
  }, [
    user.role,
    subscribeToNewPositions,
    subscribeToNewCandidates,
    subscribeToHRUpdates,
    subscribeToInterviews,
    setError
  ]);
};

export default useSubscriptions;