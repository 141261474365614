import React, { useState, useEffect } from 'react';
import { Home, Users, BarChart2, Settings, User } from 'lucide-react';

const styles = {
  sideMenu: {
    backgroundColor: '#ffffff',
    color: '#333333',
    transition: 'width 0.3s',
    overflow: 'hidden',
    boxShadow: '2px 0 10px rgba(0, 0, 0, 0.1)',
    height: '100vh',
  },
  navItem: {
    padding: '16px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    transition: 'all 0.3s ease',
    borderLeft: '4px solid transparent',
  },
  navItemActive: {
    backgroundColor: '#f0f9f9',
    borderLeft: '4px solid #4adada',
    color: '#4adada',
  },
  navItemHover: {
    backgroundColor: '#f0f9f9',
  },
  icon: {
    marginRight: '16px',
    fontSize: '20px',
    width: '24px',
    textAlign: 'center',
  },
  label: {
    fontSize: '16px',
    fontWeight: 500,
  },
};


const SideMenu = ({ activeView, setActiveView, userRole, onWidthChange }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    onWidthChange(isExpanded ? 240 : 70);
  }, [isExpanded, onWidthChange]);

  const NavItem = ({ label, view, Icon, roles }) => {
    const shouldRender = !roles || roles.includes(userRole);

    if (!shouldRender) return null;

    const isActive = activeView === view;
    return (
      <div 
        style={{
          ...styles.navItem,
          ...(isActive ? styles.navItemActive : {}),
        }}
        onClick={() => setActiveView(view)}
        onMouseEnter={(e) => !isActive && (e.currentTarget.style.backgroundColor = styles.navItemHover.backgroundColor)}
        onMouseLeave={(e) => !isActive && (e.currentTarget.style.backgroundColor = '')}
      >
        <Icon size={24} color={isActive ? '#4adada' : '#666'} style={styles.icon} />
        {isExpanded && <span style={{...styles.label, color: isActive ? '#4adada' : '#333'}}>{label}</span>}
      </div>
    );
  };

  const menuItems = [
    { label: "Dashboard", view: "dashboard", Icon: Home },
    { label: "Positions & Candidates", view: "main", Icon: Users },
    { label: "Analytics", view: "analytics", Icon: BarChart2 },
    { label: "Settings", view: "settings", Icon: Settings },
    { label: "My Profile", view: "profile", Icon: User },
  ];

  return (
    <div 
      style={{
        ...styles.sideMenu,
        width: isExpanded ? '240px' : '70px',
      }}
      onMouseEnter={() => setIsExpanded(true)}
      onMouseLeave={() => setIsExpanded(false)}
    >
      {menuItems.map((item) => (
        <NavItem key={item.view} {...item} />
      ))}
    </div>
  );
};

export default SideMenu;