import React, { useMemo } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell, LineChart, Line } from 'recharts';

const styles = {
  container: {
    padding: '20px',
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '20px',
    color: '#333',
  },
  cardGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
    gap: '20px',
    marginBottom: '20px',
  },
  card: {
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    padding: '20px',
  },
  cardTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '15px',
    color: '#4a5568',
  },
  metricItem: {
    marginBottom: '10px',
    fontSize: '16px',
    color: '#4a5568',
  },
  chartContainer: {
    height: '300px',
    marginBottom: '30px',
  },
};

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8'];

const HRAnalytics = ({ positions, candidates, interviews }) => {
  const processCandidatesByStatus = (data) => {
    return data.reduce((acc, candidate) => {
      const { status } = candidate;
      if (!acc[status]) {
        acc[status] = [];
      }
      acc[status].push(candidate);
      return acc;
    }, {});
  };

  const processHiringOverTime = (data) => {
    // Assuming this function processes the data and returns it
    return data;
  };

  const processInterviewsByType = (data) => {
    // Assuming this function processes the data and returns it
    return data;
  };

  const calculateAverageTimeToFill = (positions) => {
    // Assuming this function calculates and returns the average time to fill positions
    return positions;
  };

  const calculateOfferAcceptanceRate = (candidates) => {
    // Assuming this function calculates and returns the offer acceptance rate
    return candidates;
  };

  const getTopPerformingPositions = (positions) => {
    // Assuming this function retrieves and returns the top performing positions
    return positions;
  };

  const analyticsData = useMemo(() => {
    const candidatesByStatus = processCandidatesByStatus(candidates);
    const hiringOverTime = processHiringOverTime(candidates);
    const interviewsByType = processInterviewsByType(interviews);
    const timeToFill = calculateAverageTimeToFill(positions);
    const offerAcceptanceRate = calculateOfferAcceptanceRate(candidates);
    const topPerformingPositions = getTopPerformingPositions(positions);

    return {
      totalCandidates: candidates.length,
      activePositions: positions.filter(p => p.status === 'Open').length,
      interviewsScheduled: interviews.length,
      candidatesByStatus,
      hiringOverTime,
      interviewsByType,
      timeToFill,
      offerAcceptanceRate,
      topPerformingPositions,
    };
  }, [positions, candidates, interviews]);

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>HR Analytics</h1>
      <div style={styles.cardGrid}>
        <div style={styles.card}>
          <h2 style={styles.cardTitle}>Total Candidates</h2>
          <p style={styles.metricItem}>{analyticsData.totalCandidates}</p>
        </div>
        <div style={styles.card}>
          <h2 style={styles.cardTitle}>Active Positions</h2>
          <p style={styles.metricItem}>{analyticsData.activePositions}</p>
        </div>
        <div style={styles.card}>
          <h2 style={styles.cardTitle}>Interviews Scheduled</h2>
          <p style={styles.metricItem}>{analyticsData.interviewsScheduled}</p>
        </div>
      </div>

      <div style={styles.card}>
        <h2 style={styles.cardTitle}>Candidates by Status</h2>
        <div style={styles.chartContainer}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={analyticsData.candidatesByStatus}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="status" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="count" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>

      <div style={styles.card}>
        <h2 style={styles.cardTitle}>Hiring Over Time</h2>
        <div style={styles.chartContainer}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={analyticsData.hiringOverTime}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="count" stroke="#8884d8" />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>

      <div style={styles.card}>
        <h2 style={styles.cardTitle}>Interviews by Type</h2>
        <div style={styles.chartContainer}>
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={analyticsData.interviewsByType}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={80}
                fill="#8884d8"
                dataKey="count"
                label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
              >
                {analyticsData.interviewsByType.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>

      <div style={styles.card}>
        <h2 style={styles.cardTitle}>Top Performing Positions</h2>
        <div style={styles.chartContainer}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={analyticsData.topPerformingPositions}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="applications" fill="#ffc658" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default HRAnalytics;
