import React, { useState } from 'react';

const styles = {
  container: {
    padding: '20px',
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '20px',
    color: '#333',
  },
  section: {
    marginBottom: '20px',
  },
  sectionTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '10px',
    color: '#4a5568',
  },
  checkbox: {
    marginRight: '10px',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
    color: '#4a5568',
  },
  button: {
    backgroundColor: '#4adada',
    color: 'white',
    padding: '10px 20px',
    borderRadius: '4px',
    border: 'none',
    cursor: 'pointer',
    fontSize: '16px',
    transition: 'background-color 0.3s',
  },
};

const Settings = () => {
  const [emailNotifications, setEmailNotifications] = useState(false);
  const [dailySummary, setDailySummary] = useState(false);

  const handleSave = () => {
    console.log('Settings saved:', { emailNotifications, dailySummary });
    alert('Settings saved successfully!');
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Settings</h2>
      <div style={styles.section}>
        <h3 style={styles.sectionTitle}>Notification Preferences</h3>
        <label style={styles.label}>
          <input
            type="checkbox"
            checked={emailNotifications}
            onChange={() => setEmailNotifications(!emailNotifications)}
            style={styles.checkbox}
          />
          Email notifications for new candidates
        </label>
        <label style={styles.label}>
          <input
            type="checkbox"
            checked={dailySummary}
            onChange={() => setDailySummary(!dailySummary)}
            style={styles.checkbox}
          />
          Daily summary reports
        </label>
      </div>
      <button onClick={handleSave} style={styles.button}>
        Save Settings
      </button>
    </div>
  );
};

export default Settings;