import React, { useState, useEffect } from 'react';

const styles = {
  table: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: '0 8px',
    marginTop: '20px',
    transition: 'width 0.3s ease',
  },
  th: {
    padding: '16px',
    backgroundColor: '#4adada',
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'left',
  },
  td: {
    padding: '16px',
    borderBottom: '1px solid #e2e8f0',
    color: '#333333',
  },
  tr: {
    transition: 'all 0.3s ease',
  },
  button: {
    padding: '8px 16px',
    marginRight: '8px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  },
  editButton: {
    backgroundColor: '#4adada',
    color: 'white',
  },
  deleteButton: {
    backgroundColor: '#EF4444',
    color: 'white',
  },
  copyButton: {
    backgroundColor: '#10B981',
    color: 'white',
    padding: '4px 8px',
    fontSize: '12px',
    marginLeft: '8px',
  },
  linkContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  link: {
    maxWidth: '150px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
};

const PositionsTable = ({ positions, hrRepresentatives, clientCompanies, onEdit, onDelete, newItemId, sideMenuWidth }) => {
  const [tableWidth, setTableWidth] = useState('100%');
  const [copySuccess, setCopySuccess] = useState('');

  useEffect(() => {
    setTableWidth(`calc(100% - ${sideMenuWidth}px)`);
  }, [sideMenuWidth]);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopySuccess('Copied!');
      setTimeout(() => setCopySuccess(''), 2000);
    }, (err) => {
      console.error('Failed to copy text: ', err);
    });
  };

  const getHrRepresentativeName = (hrRepresentativeId) => {
    const hrRep = hrRepresentatives.find(hr => hr.id === hrRepresentativeId);
    return hrRep ? hrRep.name : 'N/A';
  };

  const getClientCompanyName = (clientCompanyId) => {
    const clientCompany = clientCompanies.find(cc => cc.id === clientCompanyId);
    return clientCompany ? clientCompany.name : 'N/A';
  };

  if (!Array.isArray(positions) || positions.length === 0) {
    return <p>No positions available.</p>;
  }

  return (
    <div style={{ overflowX: 'auto' }}>
      <table style={{ ...styles.table, width: tableWidth }}>
        <thead>
          <tr>
            <th style={styles.th}>Name</th>
            <th style={styles.th}>Department</th>
            <th style={styles.th}>Employment Type</th>
            <th style={styles.th}>Location</th>
            <th style={styles.th}>Client Company</th>
            <th style={styles.th}>HR Representative</th>
            <th style={styles.th}>Application Deadline</th>
            <th style={styles.th}>Status</th>
            <th style={styles.th}>Link to Position</th>
            <th style={styles.th}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {positions.map((position) => {
            if (!position || typeof position !== 'object') {
              console.error('Invalid position data:', position);
              return null;
            }
            return (
              <tr 
                key={position.id} 
                style={{
                  ...styles.tr,
                  backgroundColor: position.id === newItemId ? '#FECACA' : 'white',
                }}
              >
                <td style={styles.td}>{position.name || 'N/A'}</td>
                <td style={styles.td}>{position.department || 'N/A'}</td>
                <td style={styles.td}>{position.employmentType || 'N/A'}</td>
                <td style={styles.td}>{position.location || 'N/A'}</td>
                <td style={styles.td}>{getClientCompanyName(position.clientCompanyID)}</td>
                <td style={styles.td}>{getHrRepresentativeName(position.hrrepresentativeID)}</td>
                <td style={styles.td}>
                  {position.applicationDeadline 
                    ? new Date(position.applicationDeadline).toLocaleDateString() 
                    : 'N/A'}
                </td>
                <td style={styles.td}>{position.status || 'N/A'}</td>
                <td style={styles.td}>
                  <div style={styles.linkContainer}>
                    <span style={styles.link}>{position.positionUrl || 'N/A'}</span>
                    {position.positionUrl && (
                      <button 
                        onClick={() => copyToClipboard(position.positionUrl)}
                        style={styles.copyButton}
                      >
                        {copySuccess || 'Copy'}
                      </button>
                    )}
                  </div>
                </td>
                <td style={styles.td}>
                  <button 
                    onClick={() => onEdit(position)} 
                    style={{...styles.button, ...styles.editButton}}
                  >
                    Edit
                  </button>
                  <button 
                    onClick={() => onDelete(position.id)} 
                    style={{...styles.button, ...styles.deleteButton}}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default PositionsTable;