import { gql } from 'graphql-tag';

export const onCreateJobPosition = gql`
  subscription OnCreateJobPosition {
    onCreateJobPosition {
      id
      name
      description
      hrrepresentativeID
      clientCompanyID
      taskdefinition
      positionUrl
      department
      desiredExperience
      educationLevel
      employmentType
      responsibilities
      qualifications
      location
      employmentLocationType
      salaryRange
      benefits
      applicationDeadline
      status
      skillRequirements {
        skill
        level
      }
      createdAt
      updatedAt
    }
  }
`;

export const onUpdateJobPosition = gql`
  subscription OnUpdateJobPosition {
    onUpdateJobPosition {
      id
      name
      description
      hrrepresentativeID
      clientCompanyID
      taskdefinition
      positionUrl
      department
      desiredExperience
      educationLevel
      employmentType
      responsibilities
      qualifications
      location
      employmentLocationType
      salaryRange
      benefits
      applicationDeadline
      status
      skillRequirements {
        skill
        level
      }
      createdAt
      updatedAt
    }
  }
`;

export const onCreateCandidate = gql`
  subscription OnCreateCandidate {
    onCreateCandidate {
      id
      name
      email
      hrrepresentativeID
      overallScore
      feedback
      createdAt
      updatedAt
    }
  }
`;

export const onUpdateCandidate = gql`
  subscription OnUpdateCandidate {
    onUpdateCandidate {
      id
      name
      email
      hrrepresentativeID
      overallScore
      feedback
      createdAt
      updatedAt
    }
  }
`;

export const onCreateJobPositionCandidate = gql`
  subscription OnCreateJobPositionCandidate {
    onCreateJobPositionCandidate {
      id
      jobPositionID
      candidateID
      status
      clientCompanyID
      createdAt
      updatedAt
    }
  }
`;

export const onCreateHrrepresentative = gql`
  subscription OnCreateHrrepresentative {
    onCreateHrrepresentative {
      id
      name
      email
      role
      companyID
      createdAt
      updatedAt
    }
  }
`;

export const onCreateCompany = gql`
  subscription OnCreateCompany {
    onCreateCompany {
      id
      name
      type
      createdAt
      updatedAt
    }
  }
`;

export const onCreateClientCompany = gql`
  subscription OnCreateClientCompany {
    onCreateClientCompany {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

export const onUpdateClientCompany = gql`
  subscription OnUpdateClientCompany {
    onUpdateClientCompany {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

export const onCreateInterview = gql`
  subscription OnCreateInterview {
    onCreateInterview {
      id
      jobPositionID
      candidateID
      hrRepresentativeID
      scheduledTime
      duration
      type
      status
      notes
      createdAt
      updatedAt
    }
  }
`;

export const onUpdateInterview = gql`
  subscription OnUpdateInterview {
    onUpdateInterview {
      id
      jobPositionID
      candidateID
      hrRepresentativeID
      scheduledTime
      duration
      type
      status
      notes
      createdAt
      updatedAt
    }
  }
`;

export const onDeleteInterview = gql`
  subscription OnDeleteInterview {
    onDeleteInterview {
      id
      jobPositionID
      candidateID
      hrRepresentativeID
      scheduledTime
      duration
      type
      status
      notes
      createdAt
      updatedAt
    }
  }
`;