import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { getCurrentUser } from 'aws-amplify/auth';
import AuthPage from './components/Auth/AuthPage';
import ConfirmSignUp from './components/Auth/ConfirmSignUp';
import ForgotPassword from './components/Auth/ForgotPassword';
import Dashboard from './components/Dashboard';
import LandingPage from './components/LandingPage';
import SkillPiPrivacyPolicy from './components/SkillPiPrivacyPolicy';

function App() {
  const [user, setUser] = useState(null);
  const [authState, setAuthState] = useState('auth');
  const [tempEmail, setTempEmail] = useState('');

  useEffect(() => {
    checkUser();
  }, []);

  async function checkUser() {
    try {
      const userData = await getCurrentUser();
      setUser(userData);
    } catch (err) {
      setUser(null);
    }
  }

  const handleSignIn = (userData) => {
    setUser(userData);
  };

  const handleSignUp = (email) => {
    setTempEmail(email);
    setAuthState('confirmSignUp');
  };

  const handleConfirmSignUp = () => {
    setAuthState('auth');
  };

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route 
            path="/dashboard" 
            element={user ? <Dashboard user={user} onUserChange={setUser} /> : <Navigate to="/auth" />} 
          />
          <Route 
            path="/auth" 
            element={
              !user ? (
                <AuthPage 
                  onSignIn={handleSignIn}
                  onSignUp={handleSignUp}
                  onForgotPassword={() => setAuthState('forgotPassword')}
                />
              ) : (
                <Navigate to="/dashboard" />
              )
            } 
          />
          <Route 
            path="/confirm-signup" 
            element={
              !user && authState === 'confirmSignUp' ? (
                <ConfirmSignUp email={tempEmail} onConfirm={handleConfirmSignUp} />
              ) : (
                <Navigate to="/auth" />
              )
            } 
          />
          <Route 
            path="/forgot-password" 
            element={
              !user && authState === 'forgotPassword' ? (
                <ForgotPassword onPasswordReset={() => setAuthState('auth')} />
              ) : (
                <Navigate to="/auth" />
              )
            } 
          />
          <Route path="/privacy-policy" element={<SkillPiPrivacyPolicy />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
