import React, { useState } from 'react';
import logo from './logo.png';

const LandingPage = () => {
  const [showWaitlistForm, setShowWaitlistForm] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    hrRole: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would typically send the form data to your backend
    console.log('Form submitted:', formData);
    // Close the form and maybe show a thank you message
    setShowWaitlistForm(false);
    alert('Thank you for joining the waitlist!');
  };

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minHeight: '100vh',
      padding: '50px 20px',
      backgroundColor: 'white',
      color: '#333333',
      fontFamily: 'Arial, sans-serif',
    },
    header: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      marginBottom: '40px',
    },
    logo: {
      width: '200px',
      height: 'auto',
    },
    mainTitle: {
      fontSize: '3rem',
      color: '#4adada',
      marginBottom: '10px',
      textAlign: 'center',
    },
    subtitle: {
      fontSize: '1.8rem',
      color: '#333',
      marginBottom: '20px',
      textAlign: 'center',
    },
    earlyAccess: {
      fontSize: '1.2rem',
      color: '#ff6b6b',
      marginBottom: '30px',
      textAlign: 'center',
      fontWeight: 'bold',
    },
    description: {
      fontSize: '1.2rem',
      maxWidth: '800px',
      textAlign: 'center',
      marginBottom: '30px',
      lineHeight: '1.6',
    },
    featuresContainer: {
      display: 'flex',
      justifyContent: 'space-around',
      width: '100%',
      maxWidth: '1000px',
      marginBottom: '50px',
    },
    feature: {
      width: '30%',
      textAlign: 'center',
      padding: '20px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      borderRadius: '10px',
      backgroundColor: '#f9f9f9',
    },
    featureTitle: {
      fontSize: '1.4rem',
      color: '#4adada',
      marginBottom: '10px',
    },
    videoContainer: {
      width: '100%',
      maxWidth: '800px',
      marginBottom: '50px',
    },
    advantageSection: {
      width: '100%',
      maxWidth: '800px',
      marginBottom: '50px',
      textAlign: 'center',
    },
    advantageTitle: {
      fontSize: '2rem',
      color: '#4adada',
      marginBottom: '20px',
    },
    advantageList: {
      textAlign: 'left',
      paddingLeft: '20px',
    },
    callToAction: {
      fontSize: '1.2rem',
      fontWeight: 'bold',
      marginBottom: '20px',
      textAlign: 'center',
    },
    button: {
      backgroundColor: '#4adada',
      color: 'white',
      border: 'none',
      padding: '15px 30px',
      borderRadius: '5px',
      fontSize: '1.2rem',
      cursor: 'pointer',
      transition: 'all 0.3s ease',
    },
    buttonHover: {
      backgroundColor: '#3bc0c0',
      transform: 'translateY(-2px)',
      boxShadow: '0 4px 8px rgba(74, 218, 218, 0.3)',
    },
    footer: {
      width: '100%',
      backgroundColor: '#f0f0f0',
      padding: '20px 0',
      marginTop: '50px',
      textAlign: 'center',
    },
    footerContent: {
      display: 'flex',
      justifyContent: 'space-around',
      maxWidth: '1000px',
      margin: '0 auto',
    },
    footerColumn: {
      display: 'flex',
      flexDirection: 'column',
    },
    footerLink: {
      color: '#4adada',
      textDecoration: 'none',
      marginBottom: '5px',
    },
    copyright: {
      marginTop: '20px',
      fontSize: '0.9rem',
      color: '#666',
    },
    formOverlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
    },
    form: {
      backgroundColor: 'white',
      padding: '30px',
      borderRadius: '10px',
      width: '90%',
      maxWidth: '500px',
    },
    formTitle: {
      fontSize: '1.5rem',
      color: '#4adada',
      marginBottom: '20px',
      textAlign: 'center',
    },
    formField: {
      marginBottom: '20px',
    },
    label: {
      display: 'block',
      marginBottom: '5px',
      fontSize: '1rem',
    },
    input: {
      width: '100%',
      padding: '10px',
      fontSize: '1rem',
      border: '1px solid #ccc',
      borderRadius: '5px',
    },
    select: {
      width: '100%',
      padding: '10px',
      fontSize: '1rem',
      border: '1px solid #ccc',
      borderRadius: '5px',
      backgroundColor: 'white',
    },
    closeButton: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        fontSize: '1.5rem',
        background: 'none',
        border: 'none',
        cursor: 'pointer',
        color: '#4adada',
      },
    submitButtonContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '20px',
    },
  };

  return (
    <div style={styles.container}>
      <header style={styles.header}>
        <img src={logo} alt="SkillPi Logo" style={styles.logo} />
      </header>

      <h1 style={styles.mainTitle}>Precision-Matched Technical Talent</h1>
      <h2 style={styles.subtitle}>Revolutionize Your Hiring Process with Automated Technical Assessments</h2>
      <button 
        style={styles.button}
        onMouseEnter={(e) => Object.assign(e.target.style, styles.buttonHover)}
        onMouseLeave={(e) => Object.assign(e.target.style, styles.button)}
        onClick={() => setShowWaitlistForm(true)}
      >
        Join the Waitlist
      </button>
      
      <div style={styles.featuresContainer}>
        <div style={styles.feature}>
          <h3 style={styles.featureTitle}>Precision Matching</h3>
          <p>Accurately assess and match candidates' skills to your specific technical requirements.</p>
        </div>
        <div style={styles.feature}>
          <h3 style={styles.featureTitle}>Automated Assessments</h3>
          <p>Streamline your hiring process with automated skill tests for various technologies.</p>
        </div>
        <div style={styles.feature}>
          <h3 style={styles.featureTitle}>Top Talent Discovery</h3>
          <p>Effortlessly identify the best technical talent for your organization.</p>
        </div>
      </div>

      <div style={styles.videoContainer}>
        <div style={{position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden'}}>
          <iframe 
            style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}
            src="https://www.youtube.com/embed/NUMPsluiCLA" 
            title="SkillPi Demo" 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowFullScreen
          ></iframe>
        </div>
      </div>

      <div style={styles.advantageSection}>
        <h2 style={styles.advantageTitle}>Discover the SkillPi Advantage</h2>
        <h3>Why Join Now?</h3>
        <ul style={styles.advantageList}>
          <li>Be among the first to experience our cutting-edge hiring technology</li>
          <li>Receive exclusive early adopter benefits and pricing</li>
          <li>Shape the future of SkillPi with your valuable feedback</li>
          <li>Gain a competitive edge in securing top technical talent</li>
        </ul>
      </div>

      <p style={styles.description}>
        Our platform simplifies the evaluation of technical candidates by automating skill tests for various technologies, including Linux, programming languages, and more. Create a position, send a personalized link to candidates, and let them showcase their skills through practical exercises. Simplify your hiring, save time, and effortlessly discover the best talent.
      </p>

      <p style={styles.callToAction}>
        Don't let this opportunity slip away. Join the waitlist now!
      </p>

      <button 
        style={styles.button}
        onMouseEnter={(e) => Object.assign(e.target.style, styles.buttonHover)}
        onMouseLeave={(e) => Object.assign(e.target.style, styles.button)}
        onClick={() => setShowWaitlistForm(true)}
      >
        Join the Waitlist
      </button>

      {showWaitlistForm && (
        <div style={styles.formOverlay}>
          <div style={styles.form}>
            <button style={styles.closeButton} onClick={() => setShowWaitlistForm(false)}>×</button>
            <h2 style={styles.formTitle}>Join the Waitlist</h2>
            <form onSubmit={handleSubmit}>
              <div style={styles.formField}>
                <label style={styles.label} htmlFor="name">Name</label>
                <input
                  style={styles.input}
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div style={styles.formField}>
                <label style={styles.label} htmlFor="email">Email</label>
                <input
                  style={styles.input}
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div style={styles.formField}>
                <label style={styles.label} htmlFor="hrRole">Your Role</label>
                <select
                  style={styles.select}
                  id="hrRole"
                  name="hrRole"
                  value={formData.hrRole}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Select your role</option>
                  <option value="company">I work for an HR department inside of a company</option>
                  <option value="individual">I work HR stuff by myself</option>
                  <option value="firm">I have a Hiring firm or company</option>
                </select>
              </div>
              <div style={styles.submitButtonContainer}>
                <button 
                  type="submit" 
                  style={styles.button}
                  onMouseEnter={(e) => Object.assign(e.target.style, styles.buttonHover)}
                  onMouseLeave={(e) => Object.assign(e.target.style, styles.button)}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      <footer style={styles.footer}>
        <div style={styles.footerContent}>
          <div style={styles.footerColumn}>
            <h4>Company</h4>
            <a href="#about" style={styles.footerLink}>About Us</a>
            <a href="#careers" style={styles.footerLink}>Careers</a>
            <a href="#contact" style={styles.footerLink}>Contact</a>
          </div>
          <div style={styles.footerColumn}>
            <h4>Resources</h4>
            <a href="#blog" style={styles.footerLink}>Blog</a>
            <a href="#faq" style={styles.footerLink}>FAQ</a>
            <a href="#support" style={styles.footerLink}>Support</a>
          </div>
          <div style={styles.footerColumn}>
            <h4>Legal</h4>
            <a href="#terms" style={styles.footerLink}>Terms of Service</a>
            <a href="#privacy" style={styles.footerLink}>Privacy Policy</a>
          </div>
        </div>
        <div style={styles.copyright}>
          © 2024 SkillPi. All rights reserved.
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;