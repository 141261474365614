import React, { useEffect } from 'react';

const Notification = ({ type, message, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 5000);

    return () => clearTimeout(timer);
  }, [onClose]);

  const styles = {
    notification: {
      position: 'fixed',
      bottom: '20px',
      right: '20px',
      padding: '10px 20px',
      borderRadius: '5px',
      color: 'white',
      fontSize: '16px',
      zIndex: 1000,
    },
    success: {
      backgroundColor: '#4CAF50',
    },
    error: {
      backgroundColor: '#f44336',
    }
  };

  return (
    <div style={{...styles.notification, ...(type === 'success' ? styles.success : styles.error)}}>
      {message}
    </div>
  );
};

export default Notification;