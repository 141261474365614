import React, { useState, useCallback } from 'react';
import { signIn, signUp, confirmSignUp } from 'aws-amplify/auth';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import ForgotPassword from './ForgotPassword';

const SignInSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().required('Required'),
});

const SignUpSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string()
    .min(12, 'Password must be at least 12 characters')
    .matches(/[a-z]/, 'Password must contain at least one lowercase char')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase char')
    .matches(/[0-9]/, 'Password must contain at least one number')
    .matches(/[^a-zA-Z0-9]/, 'Password must contain at least one special char')
    .required('Required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Required'),
});

const ConfirmationSchema = Yup.object().shape({
  code: Yup.string().required('Confirmation code is required'),
});

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    backgroundColor: 'white',
    color: '#333333',
    fontFamily: 'Arial, sans-serif',
  },
  form: {
    width: '100%',
    maxWidth: '400px',
    padding: '30px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    backgroundColor: 'white',
  },
  title: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    color: '#4adada',
    marginBottom: '20px',
    textAlign: 'center',
  },
  input: {
    width: '100%',
    padding: '10px 15px',
    marginBottom: '15px',
    border: '1px solid #ddd',
    borderRadius: '4px',
    fontSize: '1rem',
  },
  button: {
    width: '100%',
    padding: '12px 20px',
    backgroundColor: '#4adada',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    fontSize: '1rem',
    fontWeight: 'bold',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  },
  errorMessage: {
    color: 'red',
    marginBottom: '15px',
    fontSize: '0.9rem',
  },
  toggle: {
    marginTop: '15px',
    textAlign: 'center',
  },
  link: {
    color: '#4adada',
    textDecoration: 'none',
    cursor: 'pointer',
  },
};

const AuthPage = ({ onSignIn }) => {
  const [isSignUp, setIsSignUp] = useState(false);
  const [needsConfirmation, setNeedsConfirmation] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [tempEmail, setTempEmail] = useState('');
  const [error, setError] = useState(null);

  const handleSignIn = useCallback(async (values, { setSubmitting, resetForm }) => {
    try {
      const user = await signIn({ username: values.email, password: values.password });
      resetForm();
      onSignIn(user);
    } catch (err) {
      setError(err.message);
    } finally {
      setSubmitting(false);
    }
  }, [onSignIn]);

  const handleSignUp = useCallback(async (values, { setSubmitting, resetForm }) => {
    try {
      await signUp({
        username: values.email,
        password: values.password,
        attributes: {
          email: values.email,
        },
      });
      setTempEmail(values.email);
      setNeedsConfirmation(true);
      resetForm();
    } catch (err) {
      setError(err.message);
    } finally {
      setSubmitting(false);
    }
  }, []);

  const handleConfirmation = useCallback(async (values, { setSubmitting, resetForm }) => {
    try {
      await confirmSignUp({ username: tempEmail, confirmationCode: values.code });
      resetForm();
      setNeedsConfirmation(false);
      setError('Account confirmed successfully. You can now sign in.');
      setIsSignUp(false);
    } catch (err) {
      setError(err.message);
    } finally {
      setSubmitting(false);
    }
  }, [tempEmail]);

  const handleForgotPassword = () => {
    setIsForgotPassword(true);
    setError(null);
  };

  const handleBackToSignIn = () => {
    setIsForgotPassword(false);
    setIsSignUp(false);
    setNeedsConfirmation(false);
    setError(null);
  };

  if (isForgotPassword) {
    return (
      <ForgotPassword
        onPasswordReset={() => {
          setIsForgotPassword(false);
          setError('Password reset successfully. You can now sign in with your new password.');
        }}
        onBackToSignIn={handleBackToSignIn}
      />
    );
  }

  return (
    <div style={styles.container}>
      <div style={styles.form}>
        {needsConfirmation ? (
          <>
            <h2 style={styles.title}>Confirm Your Account</h2>
            <p>Please enter the confirmation code sent to your email.</p>
            <Formik
              initialValues={{ code: '' }}
              validationSchema={ConfirmationSchema}
              onSubmit={handleConfirmation}
            >
              {({ errors, touched, isSubmitting }) => (
                <Form>
                  <Field name="code" type="text" placeholder="Confirmation Code" style={styles.input} />
                  {errors.code && touched.code && <div style={styles.errorMessage}>{errors.code}</div>}
                  <button type="submit" disabled={isSubmitting} style={styles.button}>
                    Confirm
                  </button>
                </Form>
              )}
            </Formik>
          </>
        ) : (
          <>
            <h2 style={styles.title}>{isSignUp ? 'Sign Up' : 'Sign In'}</h2>
            <Formik
              initialValues={isSignUp ? { email: '', password: '', confirmPassword: '' } : { email: '', password: '' }}
              validationSchema={isSignUp ? SignUpSchema : SignInSchema}
              onSubmit={isSignUp ? handleSignUp : handleSignIn}
            >
              {({ errors, touched, isSubmitting }) => (
                <Form>
                  <Field name="email" type="email" placeholder="Email" style={styles.input} />
                  {errors.email && touched.email && <div style={styles.errorMessage}>{errors.email}</div>}

                  <Field name="password" type="password" placeholder="Password" style={styles.input} />
                  {errors.password && touched.password && <div style={styles.errorMessage}>{errors.password}</div>}

                  {isSignUp && (
                    <>
                      <Field name="confirmPassword" type="password" placeholder="Confirm Password" style={styles.input} />
                      {errors.confirmPassword && touched.confirmPassword && (
                        <div style={styles.errorMessage}>{errors.confirmPassword}</div>
                      )}
                    </>
                  )}

                  <button type="submit" disabled={isSubmitting} style={styles.button}>
                    {isSignUp ? 'Sign Up' : 'Sign In'}
                  </button>
                </Form>
              )}
            </Formik>
            <div style={styles.toggle}>
              <p>
                {isSignUp ? 'Already have an account? ' : 'Don\'t have an account? '}
                <span style={styles.link} onClick={() => { setIsSignUp(!isSignUp); setError(null); }}>
                  {isSignUp ? 'Sign In' : 'Sign Up'}
                </span>
              </p>
            </div>
            {!isSignUp && (
              <div style={styles.toggle}>
                <span style={styles.link} onClick={handleForgotPassword}>Forgot Password?</span>
              </div>
            )}
          </>
        )}
        {error && <div style={styles.errorMessage}>{error}</div>}
      </div>
    </div>
  );
};

export default AuthPage;
