import React from 'react';

const styles = {
  table: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: '0 8px',
    marginTop: '20px',
  },
  th: {
    padding: '16px',
    backgroundColor: '#4adada',
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'left',
  },
  td: {
    padding: '16px',
    borderBottom: '1px solid #e2e8f0',
    color: '#333333',
  },
  tr: {
    transition: 'all 0.3s ease',
  },
  button: {
    padding: '8px 16px',
    marginRight: '8px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  },
  editButton: {
    backgroundColor: '#4adada',
    color: 'white',
  },
  deleteButton: {
    backgroundColor: '#EF4444',
    color: 'white',
  },
};

const CandidatesTable = ({ candidates, hrRepresentatives, onEdit, onDelete, newItemId }) => {
  const getHrRepresentativeName = (hrRepresentativeId) => {
    const hrRep = hrRepresentatives.find(hr => hr.id === hrRepresentativeId);
    return hrRep ? hrRep.name : 'N/A';
  };

  return (
    <table style={styles.table}>
      <thead>
        <tr>
          <th style={styles.th}>Name</th>
          <th style={styles.th}>Email</th>
          <th style={styles.th}>Overall Score</th>
          <th style={styles.th}>Feedback</th>
          <th style={styles.th}>HR Representative</th>
          <th style={styles.th}>Created At</th>
          <th style={styles.th}>Actions</th>
        </tr>
      </thead>
      <tbody>
      {candidates.map((candidate) => (
          <tr 
            key={candidate.id} 
            style={{
              ...styles.tr,
              backgroundColor: candidate.id === newItemId ? '#FECACA' : 'white',
            }}
          >
            <td style={styles.td}>{candidate.name}</td>
            <td style={styles.td}>{candidate.email}</td>
            <td style={styles.td}>{candidate.overallScore}</td>
            <td style={styles.td}>{candidate.feedback}</td>
            <td style={styles.td}>{getHrRepresentativeName(candidate.hrrepresentativeID)}</td>
            <td style={styles.td}>{new Date(candidate.createdAt).toLocaleString()}</td>
            <td style={styles.td}>
              <button 
                onClick={() => onEdit(candidate)} 
                style={{...styles.button, ...styles.editButton}}
              >
                Edit
              </button>
              <button 
                onClick={() => onDelete(candidate.id)} 
                style={{...styles.button, ...styles.deleteButton}}
              >
                Delete
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default CandidatesTable;