import React, { useState, useEffect } from 'react';

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflowY: 'auto',
  },
  modal: {
    backgroundColor: 'white',
    padding: '30px',
    borderRadius: '8px',
    width: '80%',
    maxWidth: '600px',
    maxHeight: '80vh',
    overflowY: 'auto',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },
  title: {
    marginBottom: '20px',
    color: '#333',
    fontSize: '24px',
  },
  input: {
    display: 'block',
    width: '100%',
    marginBottom: '15px',
    padding: '10px',
    borderRadius: '4px',
    border: '1px solid #e2e8f0',
    fontSize: '16px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '25px',
  },
  button: {
    padding: '10px 20px',
    borderRadius: '4px',
    border: 'none',
    cursor: 'pointer',
    fontSize: '16px',
    transition: 'background-color 0.3s ease',
  },
  cancelButton: {
    backgroundColor: '#EF4444',
    color: 'white',
  },
  submitButton: {
    backgroundColor: '#4adada',
    color: 'white',
  },
};

const AddEditCandidateModal = ({ mode, item, onClose, onSubmit, hrRepresentatives = [], currentUser }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    overallScore: '',
    feedback: '',
    phoneNumber: '',
    resume: '',
    availabilityDate: '',
    desiredSalary: '',
  });

  useEffect(() => {
    if (mode === 'edit' && item) {
      setFormData({
        id: item.id,
        name: item.name || '',
        email: item.email || '',
        overallScore: item.overallScore || '',
        feedback: item.feedback || '',
        phoneNumber: item.phoneNumber || '',
        resume: item.resume || '',
        availabilityDate: item.availabilityDate || '',
        desiredSalary: item.desiredSalary || '',
      });
    }
  }, [mode, item]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Submitting form data:', formData);
    onSubmit(formData);
  };

  console.log('Current form data:', formData);
  console.log('Current user:', currentUser);

  return (
    <div style={styles.overlay}>
      <div style={styles.modal}>
        <h2 style={styles.title}>{mode === 'add' ? 'Add' : 'Edit'} Candidate</h2>
        <form onSubmit={handleSubmit}>
          <input 
            type="text" 
            name="name" 
            placeholder="Name" 
            value={formData.name} 
            onChange={handleChange} 
            style={styles.input} 
            required
          />
          <input 
            type="email" 
            name="email" 
            placeholder="Email" 
            value={formData.email} 
            onChange={handleChange} 
            style={styles.input} 
            required
          />
          <input 
            type="number" 
            name="overallScore" 
            placeholder="Overall Score" 
            value={formData.overallScore} 
            onChange={handleChange} 
            style={styles.input}
            min="0"
            max="100"
          />
          <textarea 
            name="feedback" 
            placeholder="Feedback" 
            value={formData.feedback} 
            onChange={handleChange} 
            style={{...styles.input, height: '100px'}}
          />
          <input 
            type="tel" 
            name="phoneNumber" 
            placeholder="Phone Number" 
            value={formData.phoneNumber} 
            onChange={handleChange} 
            style={styles.input}
          />
          <input 
            type="text" 
            name="resume" 
            placeholder="Resume Link" 
            value={formData.resume} 
            onChange={handleChange} 
            style={styles.input}
          />
          <input 
            type="date" 
            name="availabilityDate" 
            placeholder="Availability Date" 
            value={formData.availabilityDate} 
            onChange={handleChange} 
            style={styles.input}
          />
          <input 
            type="number" 
            name="desiredSalary" 
            placeholder="Desired Salary" 
            value={formData.desiredSalary} 
            onChange={handleChange} 
            style={styles.input}
          />
          <div style={styles.buttonContainer}>
            <button type="button" onClick={onClose} style={{...styles.button, ...styles.cancelButton}}>Cancel</button>
            <button type="submit" style={{...styles.button, ...styles.submitButton}}>{mode === 'add' ? 'Add' : 'Update'}</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddEditCandidateModal;