import React, { useState, useEffect, useCallback } from 'react';
import { signOut } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import { generateClient } from 'aws-amplify/api';
import { getHrrepresentative } from '../graphql/queries';
import { useDataFetching } from './useDataFetching';
import { useSubscriptions } from './useSubscriptions';
import { useCrudOperations } from './useCrudOperations';
import PositionsTable from './PositionsTable';
import CandidatesTable from './CandidatesTable';
import InterviewsTable from './InterviewsTable';
import DashboardOverview from './DashboardOverview';
import HRAnalytics from './HRAnalytics';
import Settings from './Settings';
import Profile from './Profile';
import SideMenu from './SideMenu';
import AddEditPositionModal from './AddEditPositionModal';
import AddEditCandidateModal from './AddEditCandidateModal';
import AddEditInterviewModal from './AddEditInterviewModal';
import ErrorBoundary from './ErrorBoundary';
import LoadingSpinner from './LoadingSpinner';
import Notification from './Notification';
import styles, { TopBar, TabButtons } from './DashboardStyles';

import logoSrc from './logo.png';

const client = generateClient();

const Dashboard = ({ user, onUserChange }) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('positions');
  const [activeView, setActiveView] = useState('main');
  const [userRole, setUserRole] = useState('');
  const [hrInfo, setHrInfo] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [newItemId, setNewItemId] = useState(null);
  const [notification, setNotification] = useState(null);
  const [sideMenuWidth, setSideMenuWidth] = useState(70); // Default collapsed width

  const {
    positions, candidates, interviews, hrRepresentatives, clientCompanies,
    loading, error, setPositions, setCandidates, setInterviews, setError
  } = useDataFetching(user);

  useSubscriptions(user, setPositions, setCandidates, setInterviews, setNewItemId);

  const { handleDelete, handleAdd, handleEdit } = useCrudOperations(
    setPositions,
    setCandidates,
    setInterviews,
    setError,
    setNewItemId
  );

  useEffect(() => {
    const fetchHrInfo = async () => {
      if (user && user.username) {
        try {
          const hrData = await client.graphql({
            query: getHrrepresentative,
            variables: { id: user.username }
          });
          setHrInfo(hrData.data.getHrrepresentative);
          if (hrData.data.getHrrepresentative) {
            let role = hrData.data.getHrrepresentative.role;
            if (!role && hrData.data.getHrrepresentative.company) {
              switch (hrData.data.getHrrepresentative.company.type) {
                case 'STAFFING_AGENCY':
                case 'COMPANY_WITH_HR':
                  role = 'HR';
                  break;
                case 'INDEPENDENT_RECRUITER':
                  role = 'INDEPENDENT_RECRUITER';
                  break;
                default:
                  role = 'USER';
              }
            }
            setUserRole(role || 'USER');
          } else {
            setUserRole('USER');
          }
        } catch (error) {
          console.error('Error fetching HR info:', error);
          setError('Failed to fetch user information. Please try again.');
          setUserRole('USER');
        }
      }
    };

    fetchHrInfo();
  }, [user, setError]);

  const handleSignOut = async () => {
    try {
      await signOut();
      onUserChange(null);
      navigate('/signin');
    } catch (error) {
      console.error('Error signing out: ', error);
      setError('Failed to sign out. Please try again.');
    }
  };

  const canAccessView = useCallback((view) => {
    const hasAccess = {
      main: true,
      dashboard: true,
      settings: true,
      profile: true,
      analytics: ['ADMIN', 'HR', 'INDEPENDENT_RECRUITER'].includes(userRole)
    };
    return hasAccess[view] || false;
  }, [userRole]);

  const handleAddItem = useCallback(async (newItem, type) => {
    try {
      const result = await handleAdd(newItem, type, {
        id: user.username,
        companyID: hrInfo ? hrInfo.companyID : null
      });
      setShowAddModal(false);
      setNotification({ type: 'success', message: `${type.slice(0, -1)} added successfully` });
      return result;
    } catch (error) {
      console.error(`Error adding ${type}:`, error);
      setError(`Failed to add ${type}. Please try again.`);
    }
  }, [handleAdd, user.username, hrInfo, setError]);

  const handleEditItem = useCallback(async (updatedItem, type) => {
    try {
      await handleEdit(updatedItem, type);
      setShowEditModal(false);
      setEditItem(null);
      setNotification({ type: 'success', message: `${type.slice(0, -1)} updated successfully` });
    } catch (error) {
      console.error(`Error editing ${type}:`, error);
      setError(`Failed to edit ${type}. Please try again.`);
    }
  }, [handleEdit, setError]);

  const renderMainView = useCallback(() => (
    <div style={styles.mainContent}>
      <TabButtons 
        activeTab={activeTab}
        onTabChange={setActiveTab}
        onAddClick={() => {
          setError(null);
          setShowAddModal(true);
        }}
        showInterviewsTab={true}
      />
      {error && <div style={styles.errorMessage}>{error}</div>}
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          {activeTab === 'positions' && (
            <PositionsTable 
              positions={positions} 
              hrRepresentatives={hrRepresentatives}
              clientCompanies={clientCompanies}
              onEdit={(item) => { 
                setEditItem(item); 
                setShowEditModal(true); 
              }} 
              onDelete={(id) => handleDelete(id, 'positions')}
              newItemId={newItemId}
              currentUser={user}
              sideMenuWidth={sideMenuWidth}
            />
          )}
          {activeTab === 'candidates' && (
            <CandidatesTable 
              candidates={candidates} 
              hrRepresentatives={hrRepresentatives}
              onEdit={(item) => { 
                setEditItem(item); 
                setShowEditModal(true); 
              }} 
              onDelete={(id) => handleDelete(id, 'candidates')}
              newItemId={newItemId}
              currentUser={user}
            />
          )}
          {activeTab === 'interviews' && (
            <InterviewsTable 
              interviews={interviews}
              candidates={candidates}
              positions={positions}
              onEdit={(item) => {
                setEditItem(item);
                setShowEditModal(true);
              }}
              onDelete={(id) => handleDelete(id, 'interviews')}
              newItemId={newItemId}
            />
          )}
        </>
      )}
    </div>
  ), [activeTab, positions, candidates, interviews, hrRepresentatives, clientCompanies, error, loading, newItemId, user, handleDelete, setError, sideMenuWidth]);

  const renderContent = useCallback(() => {
    if (!canAccessView(activeView)) {
      return <div>You do not have permission to access this page.</div>;
    }

    switch (activeView) {
      case 'main':
        return renderMainView();
      case 'dashboard':
        return <DashboardOverview 
          positions={positions} 
          candidates={candidates} 
          interviews={interviews} 
          userRole={userRole} 
        />;
      case 'analytics':
        return <HRAnalytics 
          positions={positions} 
          candidates={candidates} 
          interviews={interviews} 
        />;
      case 'settings':
        return <Settings user={user} />;
      case 'profile':
        return <Profile user={user} onUserChange={onUserChange} />;
      default:
        return <div>Page not found</div>;
    }
  }, [activeView, canAccessView, renderMainView, positions, candidates, interviews, userRole, user, onUserChange]);

  return (
    <ErrorBoundary>
      <div style={styles.container}>
        <TopBar 
          title="SkillPI Recruitment Dashboard"
          onDashboardClick={() => setActiveView('dashboard')}
          onSignOutClick={handleSignOut}
          logoSrc={logoSrc}
        />
        <div style={styles.content}>
          <SideMenu 
            activeView={activeView} 
            setActiveView={setActiveView} 
            userRole={userRole} 
            onWidthChange={setSideMenuWidth}
          />
          <div style={{...styles.mainContentContainer, width: `calc(100% - ${sideMenuWidth}px)`}}>
            {renderContent()}
          </div>
        </div>

        {showAddModal && activeTab === 'positions' && (
          <AddEditPositionModal 
            mode="add"
            onClose={() => setShowAddModal(false)}
            onSubmit={(newItem) => handleAddItem(newItem, 'positions')}
            currentUser={{
              id: user.username,
              companyID: hrInfo ? hrInfo.companyID : null
            }}
            clientCompanies={clientCompanies}
          />
        )}

        {showEditModal && activeTab === 'positions' && editItem && (
          <AddEditPositionModal 
            mode="edit"
            item={editItem}
            onClose={() => {
              setShowEditModal(false);
              setEditItem(null);
            }}
            onSubmit={(updatedItem) => handleEditItem(updatedItem, 'positions')}
            currentUser={{
              id: user.username,
              companyID: hrInfo ? hrInfo.companyID : null
            }}
            clientCompanies={clientCompanies}
          />
        )}

        {showAddModal && activeTab === 'candidates' && (
          <AddEditCandidateModal 
            mode="add"
            onClose={() => setShowAddModal(false)}
            onSubmit={(newItem) => handleAddItem(newItem, 'candidates')}
            currentUser={{
              id: user.username,
              companyID: hrInfo ? hrInfo.companyID : null,
              role: userRole
            }}
          />
        )}

        {showEditModal && activeTab === 'candidates' && editItem && (
          <AddEditCandidateModal 
            mode="edit"
            item={editItem}
            onClose={() => {
              setShowEditModal(false);
              setEditItem(null);
            }}
            onSubmit={(updatedItem) => handleEditItem(updatedItem, 'candidates')}
            currentUser={{
              id: user.username,
              companyID: hrInfo ? hrInfo.companyID : null,
              role: userRole
            }}
          />
        )}

        {showAddModal && activeTab === 'interviews' && (
          <AddEditInterviewModal 
            mode="add"
            onClose={() => setShowAddModal(false)}
            onSubmit={(newItem) => handleAddItem(newItem, 'interviews')}
            currentUser={{
              id: user.username,
              companyID: hrInfo ? hrInfo.companyID : null
            }}
          />
        )}

        {showEditModal && activeTab === 'interviews' && editItem && (
          <AddEditInterviewModal 
            mode="edit"
            item={editItem}
            onClose={() => {
              setShowEditModal(false);
              setEditItem(null);
            }}
            onSubmit={(updatedItem) => handleEditItem(updatedItem, 'interviews')}
            currentUser={{
              id: user.username,
              companyID: hrInfo ? hrInfo.companyID : null
            }}
          />
        )}

        {notification && (
          <Notification 
            type={notification.type} 
            message={notification.message} 
            onClose={() => setNotification(null)} 
          />
        )}
      </div>
    </ErrorBoundary>
  );
};

export default Dashboard;