import React, { useState, useEffect, useCallback } from 'react';
import { generateClient } from 'aws-amplify/api';
import { listCandidates, listJobPositions } from '../graphql/queries';

const client = generateClient();

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  modal: {
    backgroundColor: 'white',
    padding: '30px',
    borderRadius: '8px',
    width: '80%',
    maxWidth: '500px',
    maxHeight: '80vh',
    overflowY: 'auto',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },
  title: {
    marginBottom: '20px',
    color: '#333',
    fontSize: '24px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    marginBottom: '5px',
    color: '#333',
    fontSize: '14px',
  },
  input: {
    marginBottom: '15px',
    padding: '10px',
    borderRadius: '4px',
    border: '1px solid #e2e8f0',
    fontSize: '16px',
  },
  select: {
    marginBottom: '15px',
    padding: '10px',
    borderRadius: '4px',
    border: '1px solid #e2e8f0',
    fontSize: '16px',
    backgroundColor: 'white',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '25px',
  },
  button: {
    padding: '10px 20px',
    borderRadius: '4px',
    border: 'none',
    cursor: 'pointer',
    fontSize: '16px',
    transition: 'background-color 0.3s ease',
  },
  cancelButton: {
    backgroundColor: '#EF4444',
    color: 'white',
  },
  submitButton: {
    backgroundColor: '#4adada',
    color: 'white',
  },
  errorMessage: {
    color: '#EF4444',
    marginBottom: '15px',
  }
};

const AddEditInterviewModal = ({ mode, item, onClose, onSubmit, currentUser }) => {
  const [formData, setFormData] = useState(mode === 'edit' ? {
    ...item,
    scheduledTime: item.scheduledTime ? new Date(item.scheduledTime).toISOString().slice(0, 16) : ''
  } : {
    jobPositionID: '',
    candidateID: '',
    hrRepresentativeID: currentUser.id,
    scheduledTime: '',
    duration: 60,
    type: 'InPerson',
    status: 'Scheduled',
    notes: '',
  });

  const [errors, setErrors] = useState({});
  const [candidates, setCandidates] = useState([]);
  const [jobPositions, setJobPositions] = useState([]);

  useEffect(() => {
    const fetchCandidatesAndPositions = async () => {
      try {
        const candidatesData = await client.graphql({ query: listCandidates });
        setCandidates(candidatesData.data.listCandidates.items);

        const positionsData = await client.graphql({ query: listJobPositions });
        setJobPositions(positionsData.data.listJobPositions.items);
      } catch (error) {
        console.error('Error fetching candidates and positions:', error);
        setErrors(prev => ({ ...prev, fetch: 'Failed to fetch candidates and positions' }));
      }
    };

    fetchCandidatesAndPositions();
  }, []);

  const validateForm = useCallback(() => {
    const newErrors = {};
    if (!formData.jobPositionID) newErrors.jobPositionID = 'Job position is required';
    if (!formData.candidateID) newErrors.candidateID = 'Candidate is required';
    if (!formData.scheduledTime) newErrors.scheduledTime = 'Scheduled time is required';
    if (!formData.duration) newErrors.duration = 'Duration is required';
    if (!formData.type) newErrors.type = 'Interview type is required';
    if (!formData.status) newErrors.status = 'Status is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [formData]);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    setErrors(prev => ({ ...prev, [name]: '' }));
  }, []);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        await onSubmit({
          id: formData.id, // Include this for updates
          jobPositionID: formData.jobPositionID,
          candidateID: formData.candidateID,
          hrRepresentativeID: currentUser.id,
          scheduledTime: new Date(formData.scheduledTime).toISOString(),
          duration: parseInt(formData.duration),
          type: formData.type,
          status: formData.status,
          notes: formData.notes || null,
        });
        onClose();
      } catch (err) {
        console.error('Error submitting interview:', err);
        setErrors({ submit: 'Failed to submit interview. Please try again.' });
      }
    }
  }, [formData, validateForm, onSubmit, onClose, currentUser]);

  return (
    <div style={styles.overlay}>
      <div style={styles.modal}>
        <h2 style={styles.title}>{mode === 'add' ? 'Schedule New Interview' : 'Edit Interview'}</h2>
        {errors.submit && <p style={styles.errorMessage}>{errors.submit}</p>}
        {errors.fetch && <p style={styles.errorMessage}>{errors.fetch}</p>}
        <form onSubmit={handleSubmit} style={styles.form}>
          <label style={styles.label}>Job Position</label>
          <select
            name="jobPositionID"
            value={formData.jobPositionID}
            onChange={handleChange}
            style={styles.select}
            required
          >
            <option value="">Select a job position</option>
            {jobPositions.map(position => (
              <option key={position.id} value={position.id}>{position.name}</option>
            ))}
          </select>
          {errors.jobPositionID && <p style={styles.errorMessage}>{errors.jobPositionID}</p>}

          <label style={styles.label}>Candidate</label>
          <select
            name="candidateID"
            value={formData.candidateID}
            onChange={handleChange}
            style={styles.select}
            required
          >
            <option value="">Select a candidate</option>
            {candidates.map(candidate => (
              <option key={candidate.id} value={candidate.id}>{candidate.name}</option>
            ))}
          </select>
          {errors.candidateID && <p style={styles.errorMessage}>{errors.candidateID}</p>}

          <label style={styles.label}>Scheduled Time</label>
          <input
            type="datetime-local"
            name="scheduledTime"
            value={formData.scheduledTime}
            onChange={handleChange}
            style={styles.input}
            required
          />
          {errors.scheduledTime && <p style={styles.errorMessage}>{errors.scheduledTime}</p>}

          <label style={styles.label}>Duration (minutes)</label>
          <select
            name="duration"
            value={formData.duration}
            onChange={handleChange}
            style={styles.select}
            required
          >
            <option value="30">30 minutes</option>
            <option value="60">1 hour</option>
            <option value="90">1.5 hours</option>
            <option value="120">2 hours</option>
          </select>
          {errors.duration && <p style={styles.errorMessage}>{errors.duration}</p>}

          <label style={styles.label}>Interview Type</label>
          <select
            name="type"
            value={formData.type}
            onChange={handleChange}
            style={styles.select}
            required
          >
            <option value="InPerson">In Person</option>
            <option value="Phone">Phone</option>
            <option value="VideoCall">Video Call</option>
          </select>
          {errors.type && <p style={styles.errorMessage}>{errors.type}</p>}

          <label style={styles.label}>Status</label>
          <select
            name="status"
            value={formData.status}
            onChange={handleChange}
            style={styles.select}
            required
          >
            <option value="Scheduled">Scheduled</option>
            <option value="Completed">Completed</option>
            <option value="Cancelled">Cancelled</option>
            <option value="Rescheduled">Rescheduled</option>
          </select>
          {errors.status && <p style={styles.errorMessage}>{errors.status}</p>}

          <label style={styles.label}>Notes</label>
          <textarea
            name="notes"
            value={formData.notes}
            onChange={handleChange}
            placeholder="Any additional notes or instructions"
            style={{ ...styles.input, minHeight: '100px' }}
          />

          <div style={styles.buttonContainer}>
            <button type="button" onClick={onClose} style={{...styles.button, ...styles.cancelButton}}>
              Cancel
            </button>
            <button type="submit" style={{...styles.button, ...styles.submitButton}}>
              {mode === 'add' ? 'Schedule Interview' : 'Update Interview'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddEditInterviewModal;