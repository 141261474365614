import { useCallback } from 'react';
import { generateClient } from 'aws-amplify/api';
import { fetchAuthSession, getCurrentUser } from '@aws-amplify/auth';
import { v4 as uuidv4 } from 'uuid';
import { 
  createJobPosition, 
  updateJobPosition, 
  deleteJobPosition, 
  createCandidate, 
  updateCandidate, 
  deleteCandidate, 
  createInterview, 
  updateInterview, 
  deleteInterview 
} from '../graphql/mutations';

const client = generateClient();

export const useCrudOperations = (
  setPositions,
  setCandidates,
  setInterviews,
  setError,
  setNewItemId
) => {
  const refreshToken = async () => {
    try {
      await fetchAuthSession();
      await getCurrentUser();
    } catch (error) {
      console.error('Error refreshing token:', error);
      throw new Error('Authentication failed. Please log in again.');
    }
  };

  const handleError = useCallback(async (operation, type, err) => {
    console.error(`Error ${operation} ${type}:`, err);
    if (err.errors) {
      console.error('GraphQL errors:', JSON.stringify(err.errors, null, 2));
    }
    if (err.networkError) {
      console.error('Network error:', err.networkError);
    }
    if (err.message && err.message.includes('Not Authorized')) {
      try {
        await refreshToken();
        setError(`Please try ${operation} ${type} again.`);
      } catch (refreshError) {
        setError('Authentication failed. Please log in again.');
      }
    } else {
      setError(`Failed to ${operation} ${type}: ${err.message || err.errors?.[0]?.message || 'Please try again.'}`);
    }
    throw err;
  }, [setError]);

  const handleAdd = useCallback(async (newItem, type, user) => {
    try {
      let input, result, createdItem;

      switch (type) {
        case 'positions':
          input = {
            name: newItem.name,
            description: newItem.description,
            hrrepresentativeID: newItem.hrrepresentativeID,
            companyID: newItem.companyID,
            clientCompanyID: newItem.clientCompanyID,
            taskdefinition: newItem.taskdefinition,
            positionUrl: newItem.positionUrl,
            department: newItem.department,
            desiredExperience: newItem.desiredExperience,
            educationLevel: newItem.educationLevel,
            employmentType: newItem.employmentType,
            responsibilities: newItem.responsibilities,
            qualifications: newItem.qualifications,
            location: newItem.location,
            employmentLocationType: newItem.employmentLocationType,
            salaryRange: newItem.salaryRange,
            benefits: newItem.benefits,
            applicationDeadline: newItem.applicationDeadline,
            status: newItem.status,
            skillRequirements: newItem.skillRequirements,
          };
          result = await client.graphql({
            query: createJobPosition,
            variables: { input }
          });
          createdItem = result.data.createJobPosition;
          setPositions(prev => [...prev, createdItem]);
          break;
        case 'candidates':
          input = {
            name: newItem.name,
            email: newItem.email,
            companyID: user.companyID,
            hrrepresentativeID: user.id,
            overallScore: newItem.overallScore,
            feedback: newItem.feedback,
            phoneNumber: newItem.phoneNumber,
            resume: newItem.resume,
            availabilityDate: newItem.availabilityDate,
            desiredSalary: newItem.desiredSalary,
            profileCompleteness: newItem.profileCompleteness,
            lastActive: new Date().toISOString(),
          };
          result = await client.graphql({
            query: createCandidate,
            variables: { input }
          });
          createdItem = result.data.createCandidate;
          setCandidates(prev => [...prev, createdItem]);
          break;
        case 'interviews':
          input = {
            jobPositionID: newItem.jobPositionID,
            candidateID: newItem.candidateID,
            hrRepresentativeID: user.id,
            scheduledTime: newItem.scheduledTime,
            duration: newItem.duration,
            type: newItem.type,
            status: newItem.status,
            notes: newItem.notes || null,
          };
          result = await client.graphql({
            query: createInterview,
            variables: { input }
          });
          createdItem = result.data.createInterview;
          setInterviews(prev => {
            const newInterviews = [...prev, createdItem];
            return newInterviews.filter((interview, index, self) =>
              index === self.findIndex((t) => t.id === interview.id)
            );
          });
          break;
        default:
          throw new Error(`Invalid type: ${type}`);
      }

      if (!createdItem) {
        throw new Error(`Failed to create ${type}. No data returned from server.`);
      }

      console.log(`${type} created:`, createdItem);
      setNewItemId(createdItem.id);
      setTimeout(() => setNewItemId(null), 3000);
      return createdItem;
    } catch (err) {
      handleError('adding', type, err);
    }
  }, [setPositions, setCandidates, setInterviews, setNewItemId, handleError]);

  const handleEdit = useCallback(async (updatedItem, type) => {
    try {
      let result;
      switch (type) {
        case 'positions':
          const input = {
            id: updatedItem.id,
            name: updatedItem.name,
            description: updatedItem.description,
            hrrepresentativeID: updatedItem.hrrepresentativeID,
            clientCompanyID: updatedItem.clientCompanyID,
            taskdefinition: updatedItem.taskdefinition,
            positionUrl: updatedItem.positionUrl,
            department: updatedItem.department,
            desiredExperience: updatedItem.desiredExperience,
            educationLevel: updatedItem.educationLevel,
            employmentType: updatedItem.employmentType,
            responsibilities: updatedItem.responsibilities,
            qualifications: updatedItem.qualifications,
            location: updatedItem.location,
            employmentLocationType: updatedItem.employmentLocationType,
            salaryRange: updatedItem.salaryRange,
            benefits: updatedItem.benefits,
            applicationDeadline: updatedItem.applicationDeadline,
            status: updatedItem.status,
            skillRequirements: updatedItem.skillRequirements,
          };
          
          // Remove any undefined or null fields
          Object.keys(input).forEach(key => {
            if (input[key] === undefined || input[key] === null) {
              delete input[key];
            }
          });

          result = await client.graphql({
            query: updateJobPosition,
            variables: { input }
          });
          setPositions(prev => prev.map(item => item.id === updatedItem.id ? result.data.updateJobPosition : item));
          break;
        case 'candidates':
          result = await client.graphql({
            query: updateCandidate,
            variables: { input: updatedItem }
          });
          setCandidates(prev => prev.map(item => item.id === updatedItem.id ? result.data.updateCandidate : item));
          break;
        case 'interviews':
          const interviewUpdateInput = {
            id: updatedItem.id,
            jobPositionID: updatedItem.jobPositionID,
            candidateID: updatedItem.candidateID,
            hrRepresentativeID: updatedItem.hrRepresentativeID,
            scheduledTime: updatedItem.scheduledTime,
            duration: updatedItem.duration,
            type: updatedItem.type,
            status: updatedItem.status,
            notes: updatedItem.notes
          };
          result = await client.graphql({
            query: updateInterview,
            variables: { input: interviewUpdateInput }
          });
          setInterviews(prev => prev.map(item => item.id === updatedItem.id ? result.data.updateInterview : item));
          break;
        default:
          throw new Error(`Invalid type: ${type}`);
      }

      console.log(`${type} updated:`, result.data[`update${type.charAt(0).toUpperCase() + type.slice(1, -1)}`]);
    } catch (err) {
      handleError('updating', type, err);
    }
  }, [setPositions, setCandidates, setInterviews, handleError]);

  const handleDelete = useCallback(async (id, type) => {
    try {
      let result;
      switch (type) {
        case 'positions':
          result = await client.graphql({
            query: deleteJobPosition,
            variables: { input: { id } }
          });
          setPositions(prev => prev.filter(item => item.id !== id));
          break;
        case 'candidates':
          result = await client.graphql({
            query: deleteCandidate,
            variables: { input: { id } }
          });
          setCandidates(prev => prev.filter(item => item.id !== id));
          break;
        case 'interviews':
          result = await client.graphql({
            query: deleteInterview,
            variables: { input: { id } }
          });
          setInterviews(prev => prev.filter(item => item.id !== id));
          break;
        default:
          throw new Error(`Invalid type: ${type}`);
      }
      console.log(`${type} deleted:`, result.data[`delete${type.charAt(0).toUpperCase() + type.slice(1, -1)}`]);
    } catch (err) {
      handleError('deleting', type, err);
    }
  }, [setPositions, setCandidates, setInterviews, handleError]);

  return { handleDelete, handleAdd, handleEdit };
};

export default useCrudOperations;