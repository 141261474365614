import React from 'react';

const SkillPiPrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>
      <p><strong>Last updated:</strong> 2024</p>
      <p>At SkillPi, the privacy of our visitors is of utmost importance to us. This privacy policy outlines the
          types of personal information we collect and how we use it.</p>
      <h2>1. Information We Collect</h2>
      <p>We collect personal information when you visit our website, register for an account, make a purchase, or
          subscribe to our newsletter. The information we may collect includes, but is not limited to:</p>
      <ul>
          <li>Name</li>
          <li>Email address</li>
          <li>Payment information (credit card, billing details)</li>
          <li>IP address</li>
          <li>Browser and device information</li>
          <li>Browsing history on our website</li>
      </ul>
      <h2>2. Use of Information</h2>
      <p>We use the collected information for the following purposes:</p>
      <ul>
          <li>To process and manage your purchase or registration.</li>
          <li>To provide customer support and technical assistance.</li>
          <li>To improve our products, services, and user experience.</li>
          <li>To send promotional communications, newsletters, and updates, provided you have consented to receive such
              communications.</li>
          <li>To comply with legal and regulatory obligations.</li>
      </ul>
      <h2>3. Sharing Information with Third Parties</h2>
      <p>We do not sell, trade, or transfer your personal information to third parties without your consent, except in
          the following cases:</p>
      <ul>
          <li>Service providers who assist us in operating our website, conducting our business, or providing services to
              you, provided these parties agree to keep this information confidential.</li>
          <li>Compliance with laws, regulations, subpoenas, or court orders.</li>
      </ul>
      <h2>4. Information Security</h2>
      <p>We implement a variety of security measures to protect the personal information of our users. However, please
          note that no method of transmission over the Internet or method of electronic storage is 100% secure.</p>
      <h2>5. Cookies and Similar Technologies</h2>
      <p>Our website uses cookies to enhance your user experience, personalize content, provide social media features, and
          analyze our traffic. You can choose to accept or decline cookies, but this may prevent you from taking full
          advantage of the website.</p>
      <h2>6. Your Privacy Rights</h2>
      <p>Depending on your location, you may have certain rights regarding your personal information, including the right
          to access, correct, delete, or restrict the use of your personal information. To exercise these rights, please
          contact us via email at <a href="mailto:skillpi@support.com">skillpi@support.com</a>.</p>
      <h2>7. Changes to This Privacy Policy</h2>
      <p>We reserve the right to update or change our privacy policy at any time. We will post any changes on this page,
          and you will be deemed to have accepted such changes if you continue to use our website after they are posted.</p>
      <h2>8. Contact Us</h2>
      <p>If you have any questions about this privacy policy or wish to exercise any of your rights, please contact us at
          <a href="mailto:skillpi@support.com"> skillpi@support.com</a>.</p>
      <p><strong>SkillPi</strong></p>
    </div>
  );
};

export default SkillPiPrivacyPolicy;
