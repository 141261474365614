import React, { useState, useEffect } from 'react';
import { generateClient } from 'aws-amplify/api';
import { getHrrepresentative } from '../graphql/queries';
import { getCurrentUser } from 'aws-amplify/auth';

const client = generateClient();

const styles = {
  container: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '20px',
    color: '#333',
  },
  form: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '20px',
  },
  fullWidth: {
    gridColumn: '1 / -1',
  },
  label: {
    display: 'block',
    marginBottom: '5px',
    color: '#4a5568',
  },
  input: {
    width: '100%',
    padding: '8px',
    borderRadius: '4px',
    border: '1px solid #e2e8f0',
  },
  select: {
    width: '100%',
    padding: '8px',
    borderRadius: '4px',
    border: '1px solid #e2e8f0',
  },
  skillsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
    marginTop: '10px',
  },
  skill: {
    backgroundColor: '#4adada',
    color: 'white',
    padding: '5px 10px',
    borderRadius: '15px',
    fontSize: '14px',
  },
  button: {
    backgroundColor: '#4adada',
    color: 'white',
    padding: '10px 20px',
    borderRadius: '4px',
    border: 'none',
    cursor: 'pointer',
    fontSize: '16px',
    transition: 'background-color 0.3s',
  },
};

const Profile = () => {
  const [profileData, setProfileData] = useState({
    id: '',
    name: '',
    email: '',
    role: '',
    company: '',
    jobPositions: [],
    phone: '',
    department: '',
    yearsOfExperience: 0,
    skills: [],
    preferredCommunicationMethod: '',
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchProfileData();
  }, []);

  const fetchProfileData = async () => {
    try {
      setLoading(true);
      setError(null);

      // Get the current authenticated user
      const { userId, username } = await getCurrentUser();

      const hrRepData = await client.graphql({
        query: getHrrepresentative,
        variables: { id: userId }
      });

      const hrRep = hrRepData.data.getHrrepresentative;

      if (hrRep) {
        setProfileData(prevData => ({
          ...prevData,
          ...hrRep,
          jobPositions: hrRep.jobPositions?.items || [],
          phone: hrRep.phone || '',
          department: hrRep.department || '',
          yearsOfExperience: hrRep.yearsOfExperience || 0,
          skills: hrRep.skills || [],
          preferredCommunicationMethod: hrRep.preferredCommunicationMethod || '',
        }));
      } else {
        setError('No HR representative data found');
      }
    } catch (error) {
      console.error('Error fetching HR representative data:', error);
      setError('Failed to fetch profile data');
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (field, value) => {
    setProfileData(prevData => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Updated Profile Data:', profileData);
    alert('Profile updated successfully!');
    // Here you would typically send the updated data to your backend
  };

  if (loading) {
    return <div>Loading profile data...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>My HR Profile</h2>
      <form onSubmit={handleSubmit} style={styles.form}>
        <div>
          <label style={styles.label}>Name</label>
          <input
            type="text"
            value={profileData.name}
            onChange={(e) => handleInputChange('name', e.target.value)}
            style={styles.input}
          />
        </div>
        <div>
          <label style={styles.label}>Email</label>
          <input
            type="email"
            value={profileData.email}
            onChange={(e) => handleInputChange('email', e.target.value)}
            style={styles.input}
          />
        </div>
        {/* Add other form fields here */}
        <div style={{...styles.fullWidth, textAlign: 'right', marginTop: '20px'}}>
          <button type="submit" style={styles.button}>
            Update Profile
          </button>
        </div>
      </form>
    </div>
  );
};

export default Profile;