import React from 'react';

export const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundColor: 'white',
    color: '#333333',
    fontFamily: 'Arial, sans-serif',
  },
  topBar: {
    backgroundColor: 'white',
    color: '#4adada',
    padding: '0 30px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    height: '70px',
    zIndex: 1000,
    position: 'relative',
  },
  logo: {
    height: '100%',
    width: 'auto',
    marginRight: '15px',
    objectFit: 'contain',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  title: {
    margin: 0,
    fontSize: '1.8rem',
    fontWeight: 'bold',
    color: '#4adada',
    textShadow: '1px 1px 2px rgba(0,0,0,0.1)',
  },
  buttonContainer: {
    display: 'flex',
    gap: '15px',
  },
  button: {
    backgroundColor: '#4adada',
    color: 'white',
    border: 'none',
    padding: '10px 15px',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '0.9rem',
    fontWeight: 'bold',
    transition: 'all 0.3s ease',
    boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
    outline: 'none',
  },
  buttonHover: {
    backgroundColor: '#3bc0c0',
    boxShadow: '0 4px 8px rgba(0,0,0,0.15)',
    transform: 'translateY(-2px)',
  },
  content: {
    display: 'flex',
    flex: 1,
    backgroundColor: 'white',
    overflow: 'hidden',
  },
  sideMenuContainer: {
    position: 'fixed',
    top: '70px',
    left: 0,
    bottom: 0,
    transition: 'width 0.3s',
    overflow: 'hidden',
    zIndex: 900,
    backgroundColor: 'white',
    boxShadow: '2px 0 10px rgba(0, 0, 0, 0.1)',
  },
  mainContentContainer: {
    flex: 1,
    marginLeft: '70px',
    transition: 'margin-left 0.3s, width 0.3s',
    overflow: 'auto',
  },
  mainContent: {
    padding: '30px',
  },
  tabButtons: {
    marginBottom: '30px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tabButton: {
    backgroundColor: '#f0f0f0',
    color: '#333333',
    border: 'none',
    padding: '12px 20px',
    marginRight: '15px',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '1rem',
    transition: 'all 0.3s ease',
    outline: 'none',
  },
  tabButtonHover: {
    backgroundColor: '#4adada',
    color: 'white',
    boxShadow: '0 4px 8px rgba(74, 218, 218, 0.3)',
  },
  activeTabButton: {
    backgroundColor: '#4adada',
    color: 'white',
    boxShadow: '0 2px 5px rgba(74, 218, 218, 0.3)',
  },
  addButton: {
    backgroundColor: '#4adada',
    color: 'white',
    border: 'none',
    padding: '12px 20px',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '1rem',
    fontWeight: 'bold',
    transition: 'all 0.3s ease',
    boxShadow: '0 2px 5px rgba(74, 218, 218, 0.3)',
    outline: 'none',
  },
  addButtonHover: {
    backgroundColor: '#3bc0c0',
    boxShadow: '0 4px 8px rgba(74, 218, 218, 0.4)',
    transform: 'translateY(-2px)',
  },
  errorMessage: {
    color: 'red',
    marginBottom: '15px',
  },
};

export const TopBar = ({ title, onDashboardClick, onSignOutClick, logoSrc }) => (
  <div style={styles.topBar}>
    <div style={styles.titleContainer}>
      {logoSrc ? (
        <img src={logoSrc} alt="Logo" style={styles.logo} />
      ) : (
        <div style={{...styles.logo, backgroundColor: '#4adada', display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 'bold', color: 'white'}}>
          SP
        </div>
      )}
      <h1 style={styles.title}>{title}</h1>
    </div>
    <div style={styles.buttonContainer}>
      <button 
        onClick={onDashboardClick} 
        style={styles.button}
        onMouseEnter={(e) => Object.assign(e.target.style, styles.buttonHover)}
        onMouseLeave={(e) => Object.assign(e.target.style, styles.button)}
      >
        Dashboard
      </button>
      <button 
        onClick={onSignOutClick} 
        style={styles.button}
        onMouseEnter={(e) => Object.assign(e.target.style, styles.buttonHover)}
        onMouseLeave={(e) => Object.assign(e.target.style, styles.button)}
      >
        Sign Out
      </button>
    </div>
  </div>
);

export const TabButtons = ({ activeTab, onTabChange, onAddClick, showInterviewsTab }) => (
  <div style={styles.tabButtons}>
    <div>
      <button 
        onClick={() => onTabChange('positions')} 
        style={activeTab === 'positions' ? {...styles.tabButton, ...styles.activeTabButton} : styles.tabButton}
        onMouseEnter={(e) => activeTab !== 'positions' && Object.assign(e.target.style, styles.tabButtonHover)}
        onMouseLeave={(e) => activeTab !== 'positions' && Object.assign(e.target.style, styles.tabButton)}
      >
        Positions
      </button>
      <button 
        onClick={() => onTabChange('candidates')} 
        style={activeTab === 'candidates' ? {...styles.tabButton, ...styles.activeTabButton} : styles.tabButton}
        onMouseEnter={(e) => activeTab !== 'candidates' && Object.assign(e.target.style, styles.tabButtonHover)}
        onMouseLeave={(e) => activeTab !== 'candidates' && Object.assign(e.target.style, styles.tabButton)}
      >
        Candidates
      </button>
      {showInterviewsTab && (
        <button 
          onClick={() => onTabChange('interviews')} 
          style={activeTab === 'interviews' ? {...styles.tabButton, ...styles.activeTabButton} : styles.tabButton}
          onMouseEnter={(e) => activeTab !== 'interviews' && Object.assign(e.target.style, styles.tabButtonHover)}
          onMouseLeave={(e) => activeTab !== 'interviews' && Object.assign(e.target.style, styles.tabButton)}
        >
          Interviews
        </button>
      )}
    </div>
    <button 
      onClick={onAddClick} 
      style={styles.addButton}
      onMouseEnter={(e) => Object.assign(e.target.style, styles.addButtonHover)}
      onMouseLeave={(e) => Object.assign(e.target.style, styles.addButton)}
    >
      Add {activeTab === 'positions' ? 'Position' : activeTab === 'candidates' ? 'Candidate' : 'Interview'}
    </button>
  </div>
);

export default styles;